<template>
  <div>
    <el-tabs v-model="activeTab" @tab-click="switchTab">
      <el-tab-pane label="Workbench" name="workbench">
        <HomeTabWorkbench
          @onViewMore="onViewMore"
          @getTasks="getTasks"
          :task-loading="taskLoading"
          :overall-tasks="overallTasks"
          :taskData="taskData"
        />
      </el-tab-pane>
      <el-tab-pane v-if="makerCheckerObject.enabled" label="Tasks" name="tasks">
        <HomeTabTask
          v-if="activeTab === 'tasks'"
          :new-fetch-intent="fetchIntents"
          :test="taskLoading"
          :unique-count-changes-by-user="uniqueCountChangesByUser"
          :format-tasks="formatTasks"
          :home-active-left-pane="homeActiveLeftPane"
          :home-active-tab="activeTab"
          :home-task-main-type="taskMainType"
        />
      </el-tab-pane>

      <el-tab-pane
        v-if="makerCheckerObject.publish && makerCheckerObject.enabled"
        label="Publisher"
        name="publisher"
      >
        <HomeTabPublisher v-if="activeTab === 'publisher'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { checkRoles, getPermittedRoles, showModule } from "@/helperMethods/auth";
import {
  getDepartmentVerifiedChanges,
  validateChangeStatusList,
  getUniqueChangeStatusList,
} from "@/helperMethods/makerChecker";
// Share methods with hometask.vue
import HomeTabPublisher from "./TabPublisher/Index";
import HomeTabTask from "./TabTask/Index";
import HomeTabWorkbench from "./TabWorkbench/Index";

export default {
  components: {
    HomeTabTask,
    HomeTabPublisher,
    HomeTabWorkbench,
    // HomeChatSummary,
    // Ratings
  },
  data() {
    return {
      // Task column
      taskLoading: false,
      taskData: [],
      homeActiveLeftPane: "pending",
      taskMainType: "",

      activeTab: "workbench",
    };
  },
  computed: {
    ...mapGetters(["getUserAuthentication", "makerCheckerObject", "departments"]),

    isAuthorizedToRead() {
      const { roles } = this.getUserAuthentication;
      const editorApproveRoles = getPermittedRoles("editor_approve");
      const editorRoles = getPermittedRoles("editor");
      const mergedRoles = _.uniq([...editorApproveRoles, ...editorRoles]);
      const isAuthorized = checkRoles(mergedRoles);
      const adminRole = roles && roles.includes("admin");

      return isAuthorized || adminRole;
    },
    overallTasks() {
      if (!this.isAuthorizedToRead) {
        return [];
      }

      const currentList = this.taskData;
      const selectedStatus = this.activeTab === "tasks" ? this.activeLeftPane : "pending";
      const { departments } = this.getUserAuthentication;
      const currentDepartmentFilteredList = getDepartmentVerifiedChanges(currentList, departments);
      return this.formatTasks(
        currentDepartmentFilteredList,
        currentList,
        selectedStatus,
        departments
      );
    },
  },
  methods: {
    checkRoles,
    getPermittedRoles,
    showModule,
    getDepartmentVerifiedChanges,

    formatTasks(currentDepartmentFilteredList, currentList, selectedStatus, departments) {
      const validatedChangeStatusList = validateChangeStatusList(
        currentDepartmentFilteredList,
        selectedStatus
      );

      const uniqueChangeStatusList = getUniqueChangeStatusList(
        validatedChangeStatusList,
        selectedStatus
      );

      const formattedChangeStatusList = _.map(uniqueChangeStatusList, (change) => {
        const { mainType, last_modified } = change;
        switch (mainType) {
          case "intent":
          case "example":
            return {
              last_modified,
              last_modified_type: _.concat(last_modified, "intent").join("_"),
              type: "Intents",
              mainType: "intent",
              list:
                this.fetchIntents(currentList, departments, last_modified, selectedStatus) || [],
              users: this.uniqueCountChangesByUser(
                this.fetchIntents(currentList, departments, last_modified, selectedStatus)
              ),
            };
          case "entity":
          case "value":
          case "pattern":
          case "synonym":
            return {
              last_modified,
              last_modified_type: _.concat(last_modified, "entity").join("_"),
              moment_last_modified: moment(last_modified, "DD-MM-YYYY HH:mm"),
              type: "Entities",
              mainType: "entity",
              list:
                this.fetchEntities(currentList, departments, last_modified, selectedStatus) || [],
              users: this.uniqueCountChangesByUser(
                this.fetchEntities(currentList, departments, last_modified, selectedStatus)
              ),
            };
          case "dialog":
            return {
              last_modified,
              last_modified_type: _.concat(last_modified, "dialog").join("_"),
              moment_last_modified: moment(last_modified, "DD-MM-YYYY HH:mm"),
              type: "Dialogs",
              mainType: "dialog",
              list:
                this.fetchDialogs(currentList, departments, last_modified, selectedStatus) || [],
              users: this.uniqueCountChangesByUser(
                this.fetchDialogs(currentList, departments, last_modified, selectedStatus)
              ),
            };
          case "editor":
            return {
              last_modified,
              last_modified_type: _.concat(last_modified, "editor").join("_"),
              moment_last_modified: moment(last_modified, "DD-MM-YYYY HH:mm"),
              type: "Editor",
              mainType: "editor",
              list: this.fetchEditor(currentList, departments, last_modified, selectedStatus) || {},
              users: this.uniqueCountChangesByUser(
                this.fetchEditor(currentList, departments, last_modified, selectedStatus)
              ),
            };
        }
      });

      return _.orderBy(
        formattedChangeStatusList,
        ["moment_last_modified", "type"],
        ["desc", "asc"]
      );
    },

    onViewMore(event) {
      (this.activeTab = event.activeTab), (this.homeActiveLeftPane = event.homeActiveLeftPane);
      this.taskMainType = event.taskMainType;
    },
    switchTab() {
      switch (this.activeTab) {
        case "workbench":
          this.getTasks();
          break;
      }
    },
    getTasks() {
      const makerCheckerEnabled = this.makerCheckerObject && this.makerCheckerObject.enabled;
      if (makerCheckerEnabled) {
        this.taskLoading = true;
        // This will call to changes table
        this.$store
          .dispatch("FETCH_CHANGES", { status: "pending" })
          .then((changes) => {
            this.taskLoading = false;
            this.taskData = changes;
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: "Encountered error. Please refresh and try again.",
            });
          });
      }
    },
    uniqueCountChangesByUser(changeBatch) {
      const defaultUserObject = {
        keys: [],
        values: [],
      };
      const uniqueUsers = _.chain(changeBatch).cloneDeep().countBy("modified_by").value();
      return uniqueUsers || defaultUserObject;
    },
    fetchIntents(chosenChangeList, departments, last_modified, selectedStatus = "pending") {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            if (selectedStatus === "pending") {
              return true;
            } else {
              return change.last_modified === last_modified;
            }
          })
          .filter((change) => {
            if (change) {
              const type = change.type.includes("_") && change.type.split("_")[1];
              return ["intent", "example"].includes(type);
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchEntities(chosenChangeList, departments, last_modified, selectedStatus = "pending") {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending" ? true : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const type = change.type.includes("_") && change.type.split("_")[1];
              return (
                ["entity", "value", "synonym", "pattern"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchDialogs(chosenChangeList, departments, last_modified, selectedStatus = "pending") {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending" ? true : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const type = change.type.includes("_") && change.type.split("_")[1];
              return (
                ["dialog"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
    fetchEditor(chosenChangeList, departments, last_modified, selectedStatus = "pending") {
      if (Array.isArray(chosenChangeList) && chosenChangeList.length > 0) {
        const currentDepartmentFilteredList = getDepartmentVerifiedChanges(
          chosenChangeList,
          departments
        );
        const finalList = _.chain(currentDepartmentFilteredList)
          .filter((change) => {
            return selectedStatus === "pending" ? true : change.last_modified === last_modified;
          })
          .filter((change) => {
            if (change) {
              const [source, type] = change.type.includes("_") && change.type.split("_");
              return (
                ["editor"].includes(source) &&
                ["content", "trigger", "form"].includes(type) &&
                (selectedStatus === "pending"
                  ? ["add", "modify", "delete"].includes(change.status)
                  : ["approve", "reject"].includes(change.status))
              );
            } else {
              return true;
            }
          })
          .value();

        return finalList;
      }
    },
  },
  mounted() {
    this.getTasks();
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

#taskTable {
  width: 100%;
  height: 100%;
}

#taskTable .el-table__body-wrapper {
  max-height: 450px !important;
}

.hr {
  display: block;
  height: 1px;
  padding: 0;
  border: 0 #ebeef5;
  border-top: 1px solid;
  margin-bottom: 1.5em;
  margin-left: 2em;
}

.bug-dialog {
  margin-top: 50px !important;
}
.approve-dialog-popup .el-dialog__body {
  margin-right: 3em;
  padding-bottom: 10px !important;
}
.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: $color-info !important;
  color: white !important;
  border-color: $color-info !important;
}

.button:hover {
  background: white !important;
  color: $color-info !important;
  border-color: $color-info !important;
}

.approve.button {
  color: #fff !important;
  background-color: $color-success !important;
  border-color: $color-success !important;
}

.approve.button:hover {
  color: $color-success !important;
  background-color: white !important;
  border-color: $color-success !important;
}

.dismiss.button {
  color: #fff !important;
  background-color: $color-grey !important;
  border-color: $color-grey !important;
}

.dismiss.button:hover {
  color: $color-grey !important;
  background-color: white !important;
  border-color: $color-grey !important;
}

.box {
  font-size: 20px;
  line-height: 20px;
  text-align: center;

  padding-top: 50px;
  padding-bottom: 50px;
  border: 2px solid $color-primary;
  background: white;
  color: $color-primary;
  width: 100%;

  transition: border 0.5s, background 0.5s;
}

.box:hover {
  background: $color-primary;
  color: white;
}

.tasks.approve {
  background-color: $color-success;
}

.feedback.subheaders {
  color: $color-dark;
}

.followup {
  color: $color-dark;
  font-size: 14px;
  padding-bottom: 5px;
}

.dialog.buttons {
  text-align: right;
}

.el-tabs__item.is-top.is-active {
  color: $color-primary !important;
}
.el-tabs__item.is-top:not(.is-active) {
  color: #303133 !important;
}
.el-tabs__item.is-top:hover {
  color: $color-primary !important;
}
</style>
