<template>
  <el-scrollbar>
    <div class="agent-chat-list">
      <el-row
        v-for="({ agent, onGoingChatCount, resolvedChatCount }, index) in filteredAgentsByKeywords"
        :key="index"
        :gutter="0"
        type="flex"
        style="width: 100%; margin-bottom: 0"
      >
        <el-col :span="24">
          <AgentChatItem
            :isExpanded="selectedAgent === agent.email"
            :agentEmail="agent.email"
            :agentName="index + 1 + '. ' + agent.name"
            :agentStatus="agent.livechat_status"
            :onGoingChatCount="onGoingChatCount"
            :resolvedChatCount="resolvedChatCount"
          />
        </el-col>
      </el-row>
    </div>
  </el-scrollbar>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AgentChatItem from "@/views/Supervisor/AgentChatItem";

export default {
  components: { AgentChatItem },
  data() {
    return {
      filteredAgentsByKeywords: [],
      searchKeyword: "",
    };
  },
  async mounted() {
    this.$store.dispatch("supervisor/FETCH_AGENTS_WITH_CHAT_COUNT", {
      email: this.$store.state.profile.email,
    });
    console.log(this.agentsWithChatCount);
    this.filteredAgentsByKeywords = this.agentsWithChatCount;
    this.$parent.$on("search:keyword:changed", (keyword) => {
      this.$store.commit("supervisor/SET_SELECTED_CHAT_SESSION", null);
      this.$store.commit("supervisor/SET_SELECTED_AGENT", { email: null });
      this.searchKeyword = keyword;
      this.filteredAgentsByKeywords = _.filter(
        this.agentsWithChatCount,
        ({ agent }) =>
          (agent.name && agent.name.includes(keyword)) ||
          (agent.email && agent.email.includes(keyword))
      );
    });
  },
  computed: {
    ...mapGetters("supervisor", [
      "selectedAgent",
      "onlineAgentsWithChatCount",
      "offlineAgentsWithChatCount",
    ]),
    ...mapState("supervisor", ["selectedChatSession", "agentsWithChatCount", "currentMode"]),
  },
  watch: {
    selectedChatSession(chat) {
      let agents = _.get(chat, "agents", []);
      if (typeof agents === "string") {
        agents = JSON.parse(agents);
      }

      if (agents.length > 0 && this.selectedAgent !== agents[0]) {
        this.$store.commit("supervisor/SET_SELECTED_AGENT", {
          email: agents[0],
        });
      }
    },
    agentsWithChatCount(val) {
      this.filteredAgentsByKeywords = val;
    },
    currentMode() {
      this.filterAgents();
    },
    searchKeyword() {
      this.filterAgents();
    },
  },
  methods: {
    filterAgents() {
      let agentList = [];
      switch (this.currentMode) {
        case "ONLINE_AGENTS":
          agentList = this.onlineAgentsWithChatCount;
          break;
        case "OFFLINE_AGENTS":
          agentList = this.offlineAgentsWithChatCount;
          break;
        default:
          agentList = this.agentsWithChatCount;
          break;
      }
      this.filteredAgentsByKeywords = _.filter(
        agentList,
        ({ agent }) =>
          (agent.name && agent.name.includes(this.searchKeyword)) ||
          (agent.email && agent.email.includes(this.searchKeyword))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
  width: 100%;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
  margin-bottom: 0 !important;
}

.agent-chat-list {
  height: calc(100vh - 60px);
}
</style>
