<template>
  <div id="form_detail_ui_component">
    <div class="ui_component_header">
      <span>UI Component</span>
      <FormUiComponentMenu @addFormUIComponent="addFormUIComponent" :isParentField="true" />
    </div>
    <div class="ui_component_body">
      <div v-if="formFieldSize > 0">
        <draggable
          v-model="formUiSchemaSetting['ui:order']"
          @start="drag = true"
          @end="drag = false"
          class="list-group"
          animation="200"
        >
          <transition-group type="transition">
            <div
              class="list-group-item"
              v-for="(field, index) in formUiSchemaSetting['ui:order']"
              :key="field"
            >
              <el-collapse accordion v-if="isFieldHasDependencies(field)" class="form_field_item">
                <el-collapse-item>
                  <template slot="title">
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin: 0 10px;
                        width: 95%;
                      "
                    >
                      <span>{{ formFieldSetting.properties[field].title }}</span>
                      <div class="form_field_actions">
                        <i
                          class="el-icon-setting has-text-info"
                          style="margin-left: 10px"
                          @click.stop="editComponent(field, index)"
                          title="Configure field"
                        ></i>
                        <i
                          class="el-icon-delete has-text-danger"
                          style="margin-left: 10px"
                          @click.stop="deleteComponent(field, index)"
                          title="Delete field"
                        ></i>
                      </div>
                    </div>
                  </template>
                  <div
                    class="dependency-item"
                    v-for="(dependency, index) in getParentFieldDependencies(field)"
                    :key="index"
                  >
                    <div class="header">
                      <span>{{ dependency.properties[field].enum[0] }}</span>
                      <FormUiComponentMenu
                        @addFormUIComponent="addFormUIComponent"
                        :isParentField="false"
                        :dependency="dependency"
                        :parentField="field"
                      />
                    </div>
                    <ul class="dependency-fields">
                      <li
                        class="dependency-field-item"
                        v-for="(childField, index) in getDependencyFields(dependency, field)"
                        :key="index"
                      >
                        <span>{{ dependency.properties[childField].title }}</span>
                        <div class="form_field_actions">
                          <i
                            class="el-icon-setting has-text-info"
                            style="margin-left: 10px"
                            @click.stop="editComponent(childField, -1, true, dependency, field)"
                            title="Configure field"
                          ></i>
                          <i
                            class="el-icon-delete has-text-danger"
                            style="margin-left: 10px"
                            @click.stop="deleteChildComponent(dependency, childField, index)"
                            title="Delete field"
                          ></i>
                        </div>
                      </li>
                    </ul>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <div class="form_field_item" v-else-if="formFieldSetting.properties[field]">
                <span>{{ formFieldSetting.properties[field].title }}</span>
                <div class="form_field_actions">
                  <i
                    class="el-icon-setting has-text-info"
                    style="margin-left: 10px"
                    @click="editComponent(field, index)"
                    title="Configure field"
                  ></i>
                  <i
                    class="el-icon-delete has-text-danger"
                    style="margin-left: 10px"
                    @click="deleteComponent(field, index)"
                    title="Delete field"
                  ></i>
                </div>
              </div>
              <div class="form_field_item" v-else-if="isFormHasDependencies > 0">
                <span style="font-weight: bold"
                  >{{ getDependencyPositionName(field) }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="This is the position that the dependencies fields show up when user select the correct value with the dependencies"
                    placement="top-start"
                  >
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </span>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
    <FormDetailUiModal
      v-if="dialogVisible"
      :variableName="editVariableName"
      :editTarget="editTarget"
      :form="form"
      :dialogVisible="dialogVisible"
      :isDependencyModal="isDependencyModal"
      :parentField="parentField"
      @changeVariableName="changeVariableName"
      @close="dialogVisible = false"
      @setUIOrder="setUiOrder"
    />
  </div>
</template>

<script>
import _ from "lodash";
import FormDetailUiModal from "./FormDetailUiModal";
import FormUiComponentMenu from "./FormUiComponentMenu";
import { v4 as uuidv4 } from "uuid";
import draggable from "vuedraggable";

export default {
  name: "FormDetailUiComponent",
  data() {
    return {
      editVariableName: "",
      editIndex: -1,
      dialogVisible: false,
      drag: false,
      isDependencyModal: false,
      editTarget: null,
      parentField: "",
      dependencyNameMapping: {},
    };
  },
  components: { FormDetailUiModal, FormUiComponentMenu, draggable },
  props: {
    form: Object,
  },
  methods: {
    addFormUIComponent({ type, isParentField, dependency, parentField }) {
      const newUIComponentVariableName = uuidv4();
      let newUIComponentProperty = {
        title: "New Field ",
        type: "string",
        description: "",
      };
      let uiSchema = {
        "ui:widget": type,
        "ui:readonly": false,
      };

      switch (type) {
        case "date":
          this.$set(newUIComponentProperty, "format", "date");
          break;
        case "dropdown":
          this.$delete(uiSchema, "ui:widget");
          this.$set(newUIComponentProperty, "enum", []);
          this.$set(newUIComponentProperty, "childField", []);
          break;
        case "radio":
          this.$set(newUIComponentProperty, "enum", []);
          break;
        case "text":
          this.$delete(uiSchema, "ui:widget");
          this.$set(newUIComponentProperty, "default", "");
          break;
        case "checkboxes":
          this.$set(uiSchema, "ui:options", { inline: true });

          this.$set(newUIComponentProperty, "uniqueItems", true);
          this.$set(newUIComponentProperty, "type", "array");
          this.$set(newUIComponentProperty, "items", {
            type: "string",
            enum: [],
          });
          break;
        default:
          break;
      }
      if (isParentField) {
        this.formUiSchemaSetting["ui:order"].push(newUIComponentVariableName);
        this.$set(
          this.formFieldSetting.properties,
          newUIComponentVariableName,
          newUIComponentProperty
        );
      } else {
        this.formFieldSetting.properties[parentField].childField.push(newUIComponentVariableName);
        this.$set(
          this.dependencyNameMapping,
          newUIComponentVariableName,
          this.formFieldSetting.properties[parentField].title
        );
        this.$set(dependency.properties, newUIComponentVariableName, newUIComponentProperty);

        const parentUIOrderIndex = this.formUiSchemaSetting["ui:order"].findIndex(
          (a) => a === parentField
        );

        this.formUiSchemaSetting["ui:order"].splice(
          parentUIOrderIndex + 1,
          0,
          newUIComponentVariableName
        );
      }

      this.$set(this.formUiSchemaSetting, newUIComponentVariableName, uiSchema);
    },
    deleteComponent(variableName, index) {
      this.$confirm("Are you sure to delete this field? ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (_.has(this.formFieldSetting.dependencies, variableName)) {
          this.removeObjectKey(this.formFieldSetting.dependencies, variableName);

          // Delete all child field in ui schema when delete parent field
          this.formFieldSetting.properties[variableName].childField?.forEach((item) => {
            this.$delete(this.formUiSchemaSetting, item);
            _.remove(this.formUiSchemaSetting["ui:order"], (field) => field === item);
          });
        }
        this.removeObjectKey(this.formFieldSetting.properties, variableName);
        this.removeObjectKey(this.formUiSchemaSetting, variableName);
        // Delete var name in required array
        const varNameRequiredIndex = _.findIndex(this.formFieldSetting.required, variableName);
        this.formFieldSetting.required.splice(varNameRequiredIndex, 1);

        // Delete var name in uiSchema order
        this.formUiSchemaSetting["ui:order"].splice(index, 1);
      });
    },
    deleteChildComponent(dependency, childField) {
      this.$confirm("Are you sure to delete this field? ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$delete(dependency.properties, childField);

        if (dependency.required) {
          const index = _.indexOf(dependency.required, childField);
          if (index !== -1) dependency.required.splice(index, 1);
        }

        this.removeObjectKey(this.formUiSchemaSetting, childField);
        const fieldIndex = this.formUiSchemaSetting["ui:order"].findIndex((a) => a === childField);
        this.formUiSchemaSetting["ui:order"].splice(fieldIndex, 1);
      });
    },
    editComponent(
      variableName,
      index,
      isDependencyModal = false,
      editTarget = this.form.settings.fields,
      parentField = ""
    ) {
      this.editVariableName = variableName;
      this.editIndex = index;
      this.editTarget = editTarget;
      this.isDependencyModal = isDependencyModal;
      this.dialogVisible = true;
      this.parentField = parentField;
    },
    changeVariableName({ oldName, newName, dependencyForm = null, parentField }) {
      if (oldName === newName) return;
      // Change variable name in properties
      this.renameObjectKey(this.formFieldSetting.properties, oldName, newName);
      // Change variable name in uiSchema widget type
      this.renameObjectKey(this.formUiSchemaSetting, oldName, newName);
      // Remove old variable in UI Schema
      this.removeObjectKey(this.formUiSchemaSetting, oldName);
      //Change variable name in uiSchema order
      if (this.editIndex !== -1) {
        this.formUiSchemaSetting["ui:order"][this.editIndex] = newName;
      }
      if (this.isDependencyModal) {
        const fieldIndex = this.formUiSchemaSetting["ui:order"].findIndex((a) => a === oldName);
        this.formUiSchemaSetting["ui:order"][fieldIndex] = newName;

        this.renameObjectKey(dependencyForm.properties, oldName, newName);
        this.renameChildVariable(parentField, oldName, newName);
        this.$set(
          this.dependencyNameMapping,
          newName,
          this.formFieldSetting.properties[parentField].title
        );
      }
      //Change variable name in dependencies array
      this.renameObjectKey(this.form.settings.fields.dependencies, oldName, newName);
    },
    renameObjectKey(object, oldKey, newKey) {
      Object.keys(object).forEach((key) => {
        if (key === oldKey) {
          object[newKey] = object[oldKey];
          this.$delete(object, oldKey);
        }
      });
    },
    removeObjectKey(object, key) {
      this.$delete(object, key);
    },
    isObjectFieldHasEnumProperty(obj) {
      return _.has(obj, "enum");
    },
    getParentFieldDependencies(variableName) {
      let result = [];
      if (_.has(this.formFieldSetting.dependencies, variableName)) {
        const dependenciesKeys = _.keys(this.formFieldSetting.dependencies[variableName]);
        result = this.formFieldSetting.dependencies[variableName][dependenciesKeys[0]];
      }
      return result;
    },
    getDependencyFields(dependency, parentField) {
      const dependencyFields = _.keys(dependency.properties);
      const result = dependencyFields.filter((item) => item !== parentField);
      return result;
    },
    isFieldHasDependencies(field) {
      if (_.has(this.form.settings.fields.dependencies, field)) {
        return this.form.settings.fields.dependencies[field].oneOf.length > 0;
      }
      return false;
    },
    dependenciesFields(field) {
      return this.formFieldSetting.dependencies[field].oneOf;
    },
    setUiOrder() {
      const fieldNames = Object.keys(this.formFieldSetting.properties);

      // Get dependencies name and add into fieldNames array
      if (_.has(this.formFieldSetting, "dependencies")) {
        const dependencies = this.formFieldSetting.dependencies;
        for (const parentField in dependencies) {
          // loop through the oneOf array for each parent field
          dependencies[parentField].oneOf.forEach((dependency) => {
            // get the dependency fields for the current oneOf element
            const dependencyFields = this.getDependencyFields(dependency, parentField);
            // loop through each dependency field
            dependencyFields.forEach((field) => {
              // add the dependency field to the field names array
              fieldNames.push(field);
              // set the mapping between the dependency field and its parent field in the dependencyNameMapping object
              this.$set(
                this.dependencyNameMapping,
                field,
                this.formFieldSetting.properties[parentField].title
              );
            });
          });
        }
      }

      // Initialize default field order.
      if (!this.formUiSchemaSetting || _.isEmpty(this.formUiSchemaSetting)) {
        this.$set(this.form.settings, "uiSchema", {
          "ui:order": fieldNames,
        });
      } else if (!_.has(this.formUiSchemaSetting, "ui:order")) {
        this.$set(this.formUiSchemaSetting, "ui:order", fieldNames);
      }

      // Initialize default value readonly for field properties.
      fieldNames.forEach((field) => {
        if (this.formUiSchemaSetting[field] && !this.formUiSchemaSetting[field]["ui:readonly"]) {
          this.$set(this.formUiSchemaSetting[field], "ui:readonly", false);
        } else if (!this.formUiSchemaSetting[field]) {
          this.$set(this.formUiSchemaSetting, field, { "ui:readonly": false });
        }

        if (!_.includes(this.formUiSchemaSetting["ui:order"], field)) {
          this.formUiSchemaSetting["ui:order"].push(field);
        }
      });

      const uiOrder = this.formUiSchemaSetting["ui:order"].filter((field) => {
        return _.includes(fieldNames, field) && field;
      });

      this.$set(this.formUiSchemaSetting, "ui:order", uiOrder);
    },
    renameChildVariable(parentField, oldName, newName) {
      const index = _.indexOf(this.formFieldSetting.properties[parentField].childField, oldName);
      if (index !== -1) this.formFieldSetting.properties[parentField].childField[index] = newName;
    },
    getDependencyPositionName(dependency) {
      return `Parent : ${this.dependencyNameMapping[dependency]} (Variable: ${dependency})`;
    },
  },
  computed: {
    formFieldSetting() {
      return this.form.settings.fields;
    },
    formUiSchemaSetting() {
      return this.form.settings.uiSchema;
    },
    formFieldSize() {
      return _.size(this.formFieldSetting.properties);
    },
    isFormHasDependencies() {
      return _.size(this.formFieldSetting.dependencies);
    },
  },
  created() {
    if (!this.formFieldSetting || _.isEmpty(this.formFieldSetting)) {
      this.$set(this.form.settings, "fields", {
        properties: {},
        required: [],
        dependencies: {},
      });
    }

    this.setUiOrder();
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";

$main-color: $color-danger;

@mixin header-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $main-color;
  color: #fff;
  min-height: 35px;
  font-size: 0.9em;
  font-weight: bold;
  padding: 5px 10px;
}

#form_detail_ui_component {
  border: 2px solid $main-color;
  border-radius: 10px;
  max-height: 700px;
  overflow: hidden;

  .ui_component_header {
    @include header-style();
  }
}

.ui_component_list {
  border: 1px solid $main-color;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;

  .ui_component_list_header {
    @include header-style();
    padding: 0 5px;
  }
  .ui_component_list_body {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    max-height: 700px;
    overflow-y: scroll;

    .ui_component_list_btn {
      max-width: 50%;
      flex-basis: 50%;
      padding: 5px;
      box-sizing: border-box;

      button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.form_field_item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  font-size: 0.9em;
  color: #333;
  border-radius: 5px;
  border: 1px solid $color-light;
  box-shadow: 0 0 5px $color-light;
  transition: all 0.5s ease;

  &:hover {
    border: 1px solid $main-color;
    color: $main-color;
  }

  i {
    font-size: 1.2em;

    &:hover {
      color: $main-color;
      cursor: pointer;
    }
  }
}

.dependency-item {
  margin: 10px;
  padding: 10px;
  background: $color-light;

  .header {
    display: flex;
    justify-content: space-between;
  }
  .dependency-fields {
    padding: 0;
    margin: 0;

    .dependency-field-item {
      @extend .form_field_item;
      background: #fff;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.ui_component_body {
  max-height: 650px;
  overflow-y: auto;
}

.list-group {
  padding: 0;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.list-group-item .el-collapse-item {
  width: 100%;
}

.list-group-item .el-collapse,
.list-group-item .el-collapse-item__header {
  border: 0;
  height: auto;
}
.list-group-item .el-collapse {
  border-radius: 5px;
  padding: 0;
  overflow: hidden;
  margin: 10px;
  height: auto;
  border: 1px solid $color-light;
}
.list-group-item .el-collapse:hover {
  border: 1px solid $color-danger;
}
</style>
