<template>
  <div>
    <el-row :gutter="20" type="flex">
      <el-col :span="24">
        <span class="fas fa-xmark fa-lg"></span>

        <div style="margin-bottom: 20px">
          <span class="automation-title">ID</span>
          {{ automation.id }}
        </div>
        <div style="margin-bottom: 20px">
          <div class="automation-title">Automation Name</div>
          <el-input
            size="small"
            maxlength="60"
            v-model="automation.name"
            @change="saveAutomation"
            style="width: 20%; margin-left: 20px"
          ></el-input>
        </div>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-card class="box-card" :body-style="{ padding: '0px' }">
              <div class="automation-item">
                <div class="automation-label">Choose Channel</div>
                <div v-for="item in channels" :key="'channel' + item.value">
                  <el-button
                    class="automation-btn"
                    :class="{ active: item.selected }"
                    size="small"
                    round
                    @click="chooseChannel(item.value)"
                  >
                    {{ item.label }}
                  </el-button>
                  <div class="automation-item-list" v-if="item.settings.endpoint">
                    <span>
                      {{ item.settings.endpoint }}
                    </span>
                  </div>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Select Contacts</div>
                <div v-if="automation.contactOperations.length > 0">
                  <div style="text-align: right">
                    <el-button
                      class="automation-btn-gray"
                      size="small"
                      circle
                      @click="openSelectContacts = !openSelectContacts"
                    >
                      <i class="automation-action-btn el-icon-close" v-if="openSelectContacts"></i>
                      <i class="automation-action-btn el-icon-plus" v-else></i>
                    </el-button>
                  </div>
                  <div
                    v-if="automation.contactOperations.includes(AUTOMATION_OPERATION.TAGS)"
                    style="margin-bottom: 10px"
                  >
                    <el-button
                      class="automation-btn active"
                      size="small"
                      round
                      @click="selectContacts(AUTOMATION_OPERATION.TAGS)"
                    >
                      Tags
                    </el-button>
                    <div class="automation-item-list">
                      <span v-for="(item, index) in checkedTagsList" :key="item.id">
                        {{ item.name }} {{ index === checkedTagsList.length - 1 ? "" : ", " }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="automation.contactOperations.includes(AUTOMATION_OPERATION.STAGES)"
                    style="margin-bottom: 10px"
                  >
                    <div>
                      <el-select
                        v-model="operation1"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-if="automation.contactOperations.includes(AUTOMATION_OPERATION.TAGS)"
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-button
                        class="automation-btn active"
                        size="small"
                        round
                        @click="selectContacts(AUTOMATION_OPERATION.STAGES)"
                      >
                        Stages
                      </el-button>
                    </div>
                    <div class="automation-item-list">
                      <span v-for="(item, index) in checkedStagesList" :key="item.id">
                        {{ item.name }} {{ index === checkedStagesList.length - 1 ? "" : ", " }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="automation.contactOperations.includes(AUTOMATION_OPERATION.LIST)"
                    style="margin-bottom: 10px"
                  >
                    <div>
                      <el-select
                        v-model="operation2"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-if="
                          automation.contactOperations.includes(AUTOMATION_OPERATION.TAGS) &&
                          automation.contactOperations.includes(AUTOMATION_OPERATION.STAGES)
                        "
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="operation1"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-else-if="
                          automation.contactOperations.includes(AUTOMATION_OPERATION.TAGS) ||
                          automation.contactOperations.includes(AUTOMATION_OPERATION.STAGES)
                        "
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-button
                        class="automation-btn active"
                        size="small"
                        round
                        @click="selectContacts(AUTOMATION_OPERATION.LIST)"
                      >
                        List
                      </el-button>
                    </div>
                    <div class="automation-item-list">
                      <span v-for="(item, index) in checkedContactsList" :key="item.id">
                        {{ item.name }} {{ index === checkedContactsList.length - 1 ? "" : ", " }}
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="automation.contactOperations.includes(AUTOMATION_OPERATION.ATTRIBUTES)"
                    style="margin-bottom: 10px"
                  >
                    <div>
                      <el-select
                        v-model="operation3"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-if="automation.contactOperations.length > 5"
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="operation2"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-else-if="automation.contactOperations.length > 3"
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="operation1"
                        placeholder="Select"
                        size="mini"
                        class="automation-operation"
                        v-else-if="automation.contactOperations.length > 1"
                        @change="handleOperationChange"
                      >
                        <el-option
                          v-for="item in operationOpt"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-button
                        class="automation-btn active"
                        size="small"
                        round
                        @click="selectContacts(AUTOMATION_OPERATION.ATTRIBUTES)"
                      >
                        Variables
                      </el-button>
                    </div>
                    <div class="automation-item-list">
                      <span v-for="(item, index) in automation.contactAttributes" :key="item.id">
                        {{ item.attribute }}
                        {{ index === automation.contactAttributes.length - 1 ? "" : ", " }}
                      </span>
                      <span>: {{ settingCountContact }}</span>
                    </div>
                  </div>
                </div>
                <div v-if="contactsOpt.length > 2 || openSelectContacts">
                  <el-select
                    v-model="operation3"
                    placeholder="Select"
                    size="mini"
                    class="automation-operation"
                    v-if="automation.contactOperations.length === 5 && contactsOpt.length > 0"
                  >
                    <el-option
                      v-for="item in operationOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="operation2"
                    placeholder="Select"
                    size="mini"
                    class="automation-operation"
                    v-else-if="automation.contactOperations.length === 3 && contactsOpt.length > 0"
                  >
                    <el-option
                      v-for="item in operationOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    v-model="operation1"
                    placeholder="Select"
                    size="mini"
                    class="automation-operation"
                    v-else-if="automation.contactOperations.length === 1 && contactsOpt.length > 0"
                  >
                    <el-option
                      v-for="item in operationOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <el-button
                    class="automation-btn"
                    :class="{ active: item.selected }"
                    size="small"
                    round
                    v-for="item in contactsOpt"
                    :key="'contacts' + item.value"
                    @click="selectContacts(item.value)"
                  >
                    {{ item.label }}
                  </el-button>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Set Message</div>
                <div
                  v-for="item in messageOpt"
                  :key="'message' + item.value"
                  style="margin-right: 10px; margin-top: 10px"
                  :style="{ display: automation.messages.length > 0 ? 'block' : 'inline' }"
                >
                  <el-button
                    class="automation-btn"
                    :class="{ active: checkedWATemplateList.length > 0 }"
                    size="small"
                    round
                    @click="setMessage(item.value)"
                    v-if="item.value === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE"
                  >
                    {{ item.label }}
                  </el-button>
                  <el-button
                    class="automation-btn"
                    :class="{ active: checkedContentNodeList.length > 0 }"
                    size="small"
                    round
                    @click="setMessage(item.value)"
                    v-else-if="item.value === AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE"
                  >
                    {{ item.label }}
                  </el-button>
                  <div
                    class="automation-item-list"
                    v-if="
                      item.value === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE &&
                      checkedWATemplateList.length > 0
                    "
                  >
                    <span v-for="(item, index) in checkedWATemplateList" :key="item.id">
                      {{ item.name }} {{ index === checkedWATemplateList.length - 1 ? "" : ", " }}
                    </span>
                  </div>
                  <div
                    class="automation-item-list"
                    v-else-if="
                      item.value === AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE &&
                      checkedContentNodeList.length > 0
                    "
                  >
                    <span v-for="(item, index) in checkedContentNodeList" :key="item.id">
                      {{ item.id }} {{ index === checkedContentNodeList.length - 1 ? "" : ", " }}
                    </span>
                  </div>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Set Context</div>
                <div>
                  <el-button
                    class="automation-btn"
                    :class="{ active: automation.context }"
                    size="small"
                    round
                    @click="setContext()"
                  >
                    Context
                  </el-button>
                  <div class="automation-item-list">
                    {{ automation.context }}
                  </div>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Update Properties : SUCCESS</div>
                <div
                  v-for="item in propertiesOpt"
                  :key="'properties' + item.value"
                  style="margin-right: 10px; margin-top: 10px"
                  :style="{ display: automation.updates.length > 0 ? 'block' : 'inline' }"
                >
                  <el-button
                    class="automation-btn"
                    :class="{ active: item.updateTo }"
                    size="small"
                    round
                    @click="updateProperties(item.value)"
                  >
                    {{ item.label }}
                  </el-button>
                  <div class="automation-item-list" v-if="item.updateTo">
                    {{ item.methodItem ? item.methodItem : "" }} {{ item.updateTo }}
                  </div>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Update Properties : FAILED</div>
                <div
                  v-for="item in propertiesFailedOpt"
                  :key="'properties' + item.value"
                  style="margin-right: 10px; margin-top: 10px"
                  :style="{ display: automation.updates.length > 0 ? 'block' : 'inline' }"
                >
                  <el-button
                    class="automation-btn"
                    :class="{ active: item.updateTo }"
                    size="small"
                    round
                    @click="updatePropertiesFailed(item.value)"
                  >
                    {{ item.label }}
                  </el-button>
                  <div class="automation-item-list" v-if="item.updateTo">
                    {{ item.methodItem ? item.methodItem : "" }} {{ item.updateTo }}
                  </div>
                </div>
              </div>
              <el-divider class="automation-divider"></el-divider>
              <div class="automation-item">
                <div class="automation-label">Send Out</div>
                <div>
                  <el-button
                    v-for="item in sendOpt"
                    :key="'send' + item.value"
                    class="automation-btn"
                    :class="{ active: item.value === automation.scheduleType }"
                    size="small"
                    round
                    @click="sendOut(item.value)"
                  >
                    {{ item.label }}
                  </el-button>
                  <div class="automation-item-list">
                    {{ sendOutSummary }}
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card
              class="box-card automation-item-setting-container"
              v-if="openSettingView"
              :body-style="{ paddingTop: '20px', paddingBottom: '10px', minHeight: '500px' }"
            >
              <div slot="header" class="clearfix">
                <el-row>
                  <el-col :span="12">
                    <span style="font-size: 16px; font-weight: 600">
                      {{ settingTitle }}
                    </span>
                    <br />
                    <small>{{ settingSubtitle }}</small>
                  </el-col>
                  <el-col :span="12" style="text-align: right">
                    <el-button
                      class="automation-btn"
                      style="color: #f56c6c"
                      circle
                      size="small"
                      @click="closeSetting"
                    >
                      <i class="automation-action-btn el-icon-close"></i>
                    </el-button>
                  </el-col>
                </el-row>
              </div>
              <div class="automation-item-setting-body">
                <div
                  class="automation-item-setting"
                  v-if="currentSetting === 'choose-channel-whatsapp'"
                >
                  <el-select v-model="selectedServer" placeholder="Select">
                    <el-option
                      v-for="item in whatsappServers"
                      :key="'wa' + item.wa_id"
                      :label="formatLabel(item.url, item.wa_id)"
                      :value="cloudAPISettings?.enabled ? item.wa_id : item.url"
                    ></el-option>
                  </el-select>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'select-contacts-tags'"
                >
                  <el-checkbox-group v-model="checkedTags" @change="handleTagsChange">
                    <el-checkbox
                      class="tags-item"
                      :label="item.id"
                      v-for="item in tagsData"
                      :key="'tags' + item.id"
                    >
                      {{ item.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'select-contacts-stages'"
                >
                  <el-checkbox-group v-model="checkedStages" @change="handleStagesChange">
                    <el-checkbox
                      class="tags-item"
                      :label="item.id"
                      v-for="item in stagesData"
                      :key="'stages' + item.id"
                    >
                      {{ item.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-else-if="currentSetting === 'select-contacts-list'">
                  <div class="automation-item-setting" style="padding-bottom: 10px">
                    <el-input
                      placeholder="Search with name, contacts or location"
                      prefix-icon="el-icon-search"
                      v-model="search"
                      size="small"
                      style="margin-bottom: 10px"
                      @input="handleSearch"
                    >
                    </el-input>
                    <el-checkbox
                      :indeterminate="isIndeterminate"
                      v-model="checkAllContacts"
                      @change="handleCheckAllContacts"
                    >
                      Name
                    </el-checkbox>
                  </div>
                  <el-divider class="automation-divider"></el-divider>
                  <div
                    class="automation-item-setting"
                    style="padding: 10px 20px; max-height: 380px"
                  >
                    <el-checkbox-group v-model="checkedContacts" @change="handleCheckedContacts">
                      <el-checkbox
                        class="tags-item"
                        :label="item.id"
                        v-for="item in listOpt"
                        :key="'contact' + item.id"
                      >
                        {{ item.name }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="automation-item-setting" v-if="loadMore">
                    <div
                      @click="handleLoadMore"
                      style="text-align: center; margin-top: 10px; cursor: pointer"
                    >
                      <u>Load more...</u>
                    </div>
                  </div>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'select-contacts-attributes'"
                >
                  <div v-for="(item, index) in checkedAttributes" :key="'cattr-' + index">
                    <el-row>
                      <el-col :span="9">
                        <el-select v-model="item.attribute" size="mini" placeholder="Select">
                          <el-option
                            v-for="opt in contactAttributesOpt"
                            :key="'cvar' + opt.value"
                            :value="opt.value"
                            :label="opt.label"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="6">
                        <el-select
                          v-model="item.comparison"
                          size="mini"
                          placeholder="Select"
                          @change="filterByCheckedAttributes"
                        >
                          <el-option
                            v-for="opt in attributesOperationOpt"
                            :key="'cvar' + opt.value"
                            :value="opt.comparison"
                            :label="opt.label"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="9">
                        <el-select
                          v-model="item.value"
                          size="mini"
                          filterable
                          default-first-option
                          :reserve-keyword="false"
                          placeholder="Select"
                          @change="filterByCheckedAttributes"
                        >
                          <el-option
                            v-for="(item, index) in getAttributesValue(item.attribute, item)"
                            :key="index"
                            :value="item"
                            :label="item"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                    <el-divider></el-divider>
                  </div>
                  <div style="text-align: center; margin-top: 15px">
                    <el-button
                      class="automation-btn"
                      size="small"
                      round
                      @click="addContactAttributes"
                    >
                      Add Variables
                    </el-button>
                  </div>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'set-message-whatsapp'"
                >
                  <div v-for="(item, index) in checkedWATemplateItem" :key="'wamsg-' + index">
                    <el-row>
                      <el-col :span="8">
                        <div class="automation-input-label">Message #{{ index + 1 }}</div>
                      </el-col>
                      <el-col :span="16">
                        <el-select
                          v-model="item.contentId"
                          size="small"
                          placeholder="Select"
                          @change="handleWATemplateChange()"
                        >
                          <el-option
                            v-for="temp in whatsappTemplates"
                            :key="'watmp' + temp.id"
                            :value="temp.id"
                            :label="temp.name + '(' + temp.language + ')'"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>

                    <div v-if="item.headerText">
                      <div class="automation-input-label">Header</div>
                      <p>{{ item.headerText }}</p>
                    </div>

                    <div v-if="item.bodyText">
                      <div class="automation-input-label">Body</div>
                      <p>{{ item.bodyText }}</p>
                    </div>

                    <div v-if="item.allParameters">
                      <el-row
                        v-for="(param, idx) in item.allParameters"
                        :key="'param' + index + idx"
                      >
                        <el-col :span="8">
                          <div class="automation-input-label">{{ param.label }}</div>
                        </el-col>
                        <el-col :span="6">
                          <el-select v-model="param.type" size="mini" placeholder="Select">
                            <el-option
                              v-for="opt in messageParameterType"
                              :key="'type' + opt.value"
                              :value="opt.value"
                            >
                              {{ opt.label }}
                            </el-option>
                          </el-select>
                        </el-col>
                        <el-col :span="10">
                          <el-input
                            v-model="param.value"
                            size="mini"
                            v-if="param.type === AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT"
                          ></el-input>
                          <el-select v-model="param.value" size="mini" placeholder="Select" v-else>
                            <el-option
                              v-for="opt in messageParamaterVariables"
                              :key="'type' + opt.value"
                              :value="opt.value"
                            >
                              {{ opt.label }}
                            </el-option>
                          </el-select>
                        </el-col>
                      </el-row>
                    </div>
                    <el-divider></el-divider>
                  </div>
                  <div style="text-align: center; margin-top: 15px">
                    <el-button
                      class="automation-btn"
                      size="small"
                      round
                      @click="addMessageWATemplate"
                    >
                      Add Message
                    </el-button>
                  </div>
                </div>
                <div v-else-if="currentSetting === 'set-message-content-node'">
                  <div class="automation-item-setting" style="padding-bottom: 10px">
                    <el-input
                      placeholder="Search content node"
                      prefix-icon="el-icon-search"
                      v-model="searchContentNode"
                      size="small"
                      style="margin-bottom: 10px"
                    >
                    </el-input>
                  </div>
                  <el-divider class="automation-divider"></el-divider>
                  <div
                    class="automation-item-setting"
                    style="padding: 10px 20px; max-height: 430px"
                  >
                    <el-checkbox-group v-model="checkedContentNode">
                      <el-checkbox
                        class="tags-item"
                        :label="item.id"
                        v-for="item in contentNodesFilter"
                        :key="'content' + item.id"
                      >
                        {{ item.id }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="automation-item-setting" v-else-if="currentSetting === 'set-context'">
                  <el-input placeholder="Context" v-model="currentContext" size="small"></el-input>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-tags'"
                >
                  <el-radio-group
                    v-model="propertiesOpt[AUTOMATION_OPERATION.TAGS].method"
                    style="margin-bottom: 15px"
                  >
                    <el-radio
                      class="tags-item"
                      :label="item.value"
                      v-for="item in methodOpt"
                      :key="'method' + item.value"
                    >
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                  <el-checkbox-group
                    v-model="propertiesOpt[AUTOMATION_OPERATION.TAGS].selected"
                    v-if="propertiesOpt[AUTOMATION_OPERATION.TAGS].method"
                  >
                    <el-checkbox
                      class="tags-item"
                      :label="item.id"
                      v-for="item in tagsData"
                      :key="'tagsp' + item.id"
                    >
                      {{ item.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-stages'"
                >
                  <el-radio-group v-model="propertiesOpt[AUTOMATION_OPERATION.STAGES].selected">
                    <el-radio
                      class="tags-item"
                      :label="item.id"
                      v-for="item in stagesData"
                      :key="'stagesp' + item.id"
                    >
                      {{ item.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-failed-tags'"
                >
                  <el-radio-group
                    v-model="propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method"
                    style="margin-bottom: 15px"
                  >
                    <el-radio
                      class="tags-item"
                      :label="item.value"
                      v-for="item in methodOpt"
                      :key="'method' + item.value"
                    >
                      {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                  <el-checkbox-group
                    v-model="propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selected"
                    v-if="propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method"
                  >
                    <el-checkbox
                      class="tags-item"
                      :label="item.id"
                      v-for="item in tagsData"
                      :key="'tagsp' + item.id"
                    >
                      {{ item.name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-attributes'"
                >
                  <div
                    v-for="(item, index) in propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected"
                    :key="'attrp-' + index"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-select v-model="item.key" size="mini" placeholder="Select">
                          <el-option
                            v-for="opt in updateAttributesOpt"
                            :key="'cvar' + opt.value"
                            :value="opt.value"
                            :label="opt.label"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="item.value" size="mini"></el-input>
                      </el-col>
                    </el-row>
                    <el-divider></el-divider>
                  </div>
                  <div style="text-align: center; margin-top: 15px">
                    <el-button
                      class="automation-btn"
                      size="small"
                      round
                      @click="addUpdatePropertiesAttr(false)"
                    >
                      Add Variables
                    </el-button>
                  </div>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-failed-stages'"
                >
                  <el-radio-group
                    v-model="propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected"
                  >
                    <el-radio
                      class="tags-item"
                      :label="item.id"
                      v-for="item in stagesData"
                      :key="'stagesp' + item.id"
                    >
                      {{ item.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'update-properties-failed-attributes'"
                >
                  <div
                    v-for="(item, index) in propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES]
                      .selected"
                    :key="'attrp-' + index"
                  >
                    <el-row>
                      <el-col :span="12">
                        <el-select v-model="item.key" size="mini" placeholder="Select">
                          <el-option
                            v-for="opt in updateAttributesOpt"
                            :key="'cvar' + opt.value"
                            :value="opt.value"
                            :label="opt.label"
                          ></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="12">
                        <el-input v-model="item.value" size="mini"></el-input>
                      </el-col>
                    </el-row>
                    <el-divider></el-divider>
                  </div>
                  <div style="text-align: center; margin-top: 15px">
                    <el-button
                      class="automation-btn"
                      size="small"
                      round
                      @click="addUpdatePropertiesAttr(true)"
                    >
                      Add Variables
                    </el-button>
                  </div>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'send-out-schedule'"
                >
                  <el-row :gutter="20">
                    <el-col :span="14">
                      <el-date-picker
                        v-model="startDate"
                        type="date"
                        size="small"
                        placeholder="Pick a day"
                        style="width: 100%"
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                      <el-time-select
                        v-model="startTime"
                        size="small"
                        placeholder="Select time"
                        :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"
                        style="width: 100%"
                      >
                      </el-time-select>
                    </el-col>
                  </el-row>
                </div>
                <div
                  class="automation-item-setting"
                  v-else-if="currentSetting === 'send-out-recurring'"
                >
                  <el-row :gutter="20">
                    <el-col :span="14">
                      <div class="automation-input-label">Start date</div>
                      <el-date-picker
                        v-model="startDate"
                        type="date"
                        size="small"
                        placeholder="Pick a day"
                        style="width: 100%"
                        :picker-options="pickerOptions"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                      <div class="automation-input-label">Start time</div>
                      <el-time-select
                        v-model="startTime"
                        size="small"
                        placeholder="Select time"
                        :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"
                        style="width: 100%"
                      >
                      </el-time-select>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="14">
                      <div class="automation-input-label">Repeat</div>
                      <el-select v-model="repeatType" placeholder="Select" size="small">
                        <el-option
                          v-for="item in repeatOpt"
                          :key="'repeat' + item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="10">
                      <div class="automation-input-label">Repeat Every</div>
                      <el-date-picker
                        id="repeatDates"
                        v-model="repeatDates"
                        type="dates"
                        size="small"
                        placeholder="Pick dates"
                        style="width: 100%"
                        :picker-options="pickerOptions"
                        v-if="repeatType === 'Dates'"
                      >
                      </el-date-picker>
                      <el-input-number
                        v-model="repeatTime"
                        controls-position="right"
                        :min="1"
                        size="small"
                        :max="repeatType === 'Daily' ? 2 : 1"
                        v-else
                      ></el-input-number>
                    </el-col>
                  </el-row>
                  <div style="margin-bottom: 10px">
                    <div class="automation-input-label">End</div>
                    <el-radio-group v-model="endFlag">
                      <el-radio class="tags-item" label="never"> Never </el-radio>
                      <el-radio class="tags-item" label="on"> On </el-radio>
                    </el-radio-group>
                  </div>
                  <el-row :gutter="20" v-if="endFlag === 'on'">
                    <el-col :span="14">
                      <div class="automation-input-label">End date</div>
                      <el-date-picker
                        v-model="endDate"
                        type="date"
                        size="small"
                        placeholder="Pick a day"
                        style="width: 100%"
                        :picker-options="pickerOptionsEnd"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="10">
                      <div class="automation-input-label">End time</div>
                      <el-time-select
                        v-model="endTime"
                        size="small"
                        placeholder="Select time"
                        :picker-options="{ start: '00:00', step: '00:05', end: '23:55' }"
                        style="width: 100%"
                      >
                      </el-time-select>
                    </el-col>
                  </el-row>
                  <div>
                    <div class="automation-input-label">Summary</div>
                    <div>{{ repeatSummary }}</div>
                    {{ repeatDates }}
                  </div>
                </div>
                <div class="automation-item-setting" v-else>No Action</div>
              </div>
              <div class="automation-item-setting-footer" style="margin-top: auto">
                <div>
                  <el-button
                    size="small"
                    @click="resetSetting"
                    icon="el-icon-refresh-left"
                    plain
                    type="success"
                  >
                    Reset
                  </el-button>
                  <el-button
                    size="small"
                    type="primary"
                    @click="saveSetting"
                    icon="el-icon-check"
                    plain
                  >
                    Save
                  </el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  AUTOMATION_CHANNEL_NAME,
  AUTOMATION_SCHEDULE_TYPE,
  AUTOMATION_OPERATION,
  AUTOMATION_MESSAGE_CONTENT_TYPE,
  AUTOMATION_MESSAGE_PARAMETER_TYPE,
  AUTOMATION_UPDATE_METHOD,
  AUTOMATION_ATTRIBUTE_OPERATOR,
  OUT_REACH_TAG_TYPE,
} from "@/store/modules/outreach/types";
import {
  OUT_REACH_FILTER_COMPARISON,
  getOutReachVariableExcludeField,
} from "@/helperMethods/outreach";

export default {
  components: {},
  data() {
    return {
      AUTOMATION_OPERATION,
      AUTOMATION_MESSAGE_CONTENT_TYPE,
      AUTOMATION_MESSAGE_PARAMETER_TYPE,
      automation: {
        id: null,
        name: "",
        context: "",
        enabled: false,
        channels: [],
        messages: [],
        contactTags: [],
        contactStages: [],
        contactList: [],
        contactAttributes: [],
        contactOperations: [],
        scheduleType: AUTOMATION_SCHEDULE_TYPE.NOT_SET,
        scheduleCron: [],
        startDate: null,
        endDate: null,
        sentTime: null,
        updates: [],
      },
      contentNodes: [],
      channels: {},
      whatsappServers: [],
      selectedServer: "",
      checkedTags: [],
      checkedTagsList: [],
      checkedStages: [],
      checkedStagesList: [],
      checkedContacts: [],
      checkedContactsList: [],
      checkAllContacts: false,
      isIndeterminate: false,
      openSelectContacts: false,
      checkedAttributes: [],
      checkedWATemplate: [],
      checkedWATemplateItem: [],
      checkedWATemplateList: [],
      checkedContentNode: [],
      checkedContentNodeList: [],
      search: "",
      searchContentNode: "",
      currentContext: "",
      filter: [],
      sort: [],
      startDate: moment(),
      startTime: "00:00",
      endFlag: "",
      endDate: moment(),
      endTime: "00:00",
      repeatType: "Daily",
      repeatTime: 1,
      repeatDates: "",
      repeatSummary: "",
      sendOutSummary: "",
      openSettingView: false,
      currentSetting: "",
      settingTitle: "",
      settingSubtitle: "",
      settingCountContact: "0 Contacts",
      operation1: AUTOMATION_OPERATION.AND,
      operation2: AUTOMATION_OPERATION.AND,
      operation3: AUTOMATION_OPERATION.AND,
      operationOpt: [
        {
          value: AUTOMATION_OPERATION.AND,
          label: "+ and",
        },
        {
          value: AUTOMATION_OPERATION.OR,
          label: "/ or",
        },
      ],
      attributesOperationOpt: [
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
          label: "=",
          comparison: "is",
        },
        {
          value: AUTOMATION_ATTRIBUTE_OPERATOR.NOT_EQUAL,
          label: "!=",
          comparison: "isNot",
        },
      ],
      messageOpt: [
        {
          value: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
          label: "Whatsapp Template",
        },
      ],
      messageParameterType: [
        {
          value: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
          label: "Text",
        },
        {
          value: AUTOMATION_MESSAGE_PARAMETER_TYPE.VARIABLE,
          label: "Variable",
        },
      ],
      propertiesOpt: {
        [AUTOMATION_OPERATION.TAGS]: {
          value: AUTOMATION_OPERATION.TAGS,
          label: "Tags",
          method: "",
          methodItem: "",
          selected: [],
          selectedItem: [],
          updateTo: "",
        },
        [AUTOMATION_OPERATION.STAGES]: {
          value: AUTOMATION_OPERATION.STAGES,
          label: "Stages",
          selected: null,
          selectedItem: null,
          updateTo: "",
        },
        [AUTOMATION_OPERATION.ATTRIBUTES]: {
          value: AUTOMATION_OPERATION.ATTRIBUTES,
          label: "Variables",
          selected: [],
          selectedItem: [],
          updateTo: "",
        },
      },
      propertiesFailedOpt: {
        [AUTOMATION_OPERATION.TAGS]: {
          value: AUTOMATION_OPERATION.TAGS,
          label: "Tags",
          method: "",
          methodItem: "",
          selected: [],
          selectedItem: [],
          updateTo: "",
        },
        [AUTOMATION_OPERATION.STAGES]: {
          value: AUTOMATION_OPERATION.STAGES,
          label: "Stages",
          selected: null,
          selectedItem: null,
          updateTo: "",
        },
        [AUTOMATION_OPERATION.ATTRIBUTES]: {
          value: AUTOMATION_OPERATION.ATTRIBUTES,
          label: "Variables",
          selected: [],
          selectedItem: [],
          updateTo: "",
        },
      },
      methodOpt: [
        {
          value: AUTOMATION_UPDATE_METHOD.OVERRIDE,
          label: "Overwrite Existing",
        },
        {
          value: AUTOMATION_UPDATE_METHOD.ADD,
          label: "Add new",
        },
      ],
      sendOpt: [
        {
          value: AUTOMATION_SCHEDULE_TYPE.SEND_NOW,
          label: "Send Now",
        },
        {
          value: AUTOMATION_SCHEDULE_TYPE.SCHEDULED,
          label: "Schedule",
        },
        {
          value: AUTOMATION_SCHEDULE_TYPE.RECURRING,
          label: "Recurring",
        },
      ],
      repeatOpt: [
        {
          value: "Daily",
          label: "Daily",
        },
        {
          value: "Weekly",
          label: "Weekly",
        },
        {
          value: "Monthly",
          label: "Monthly",
        },
        {
          value: "Yearly",
          label: "Yearly",
        },
        {
          value: "Dates",
          label: "Dates",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getDate() < moment().date();
        },
      },
      clientTimezone: moment.tz.guess(),
    };
  },
  computed: {
    ...mapGetters(["contactAttributes", "contactsArray"]),
    messageParamaterVariables() {
      return getOutReachVariableExcludeField(undefined, this.contactAttributes);
    },
    contactAttributesData() {
      return this.automation.contactAttributes.length > 0;
    },
    contactAttributesOpt() {
      var variables = getOutReachVariableExcludeField(undefined, this.contactAttributes);
      var items = [];
      for (let index = 0; index < variables.length; index++) {
        const item = variables[index];
        if (item.value !== "tags" && item.value !== "stage" && item.value !== "name") {
          if (item.attributeType === undefined && item.options === undefined) {
            var itemsOptions = [];
            if (!item.options) {
              itemsOptions = _.map(this.contactsArray.data, function (entity) {
                return entity[item.value];
              });
            }
            items.push({
              label: item.label,
              value: item.value,
              attributeType: "dropdown",
              options: item.options ? item.options : [...new Set(itemsOptions)],
            });
          } else {
            items.push(item);
          }
        }
      }
      return items;
    },
    updateAttributesOpt() {
      const variables = _.reduce(
        this.contactAttributes,
        function (result, item) {
          result.push({
            label: item.label,
            value: item.name,
          });
          return result;
        },
        []
      );

      return variables;
    },
    contactsOpt() {
      if (this.automation.contactOperations.includes(AUTOMATION_OPERATION.ATTRIBUTES)) {
        return [];
      } else if (this.automation.contactOperations.includes(AUTOMATION_OPERATION.LIST)) {
        return [
          {
            value: AUTOMATION_OPERATION.ATTRIBUTES,
            label: "Variables",
          },
        ];
      } else if (this.automation.contactOperations.includes(AUTOMATION_OPERATION.STAGES)) {
        return [
          {
            value: AUTOMATION_OPERATION.LIST,
            label: "List",
          },
          {
            value: AUTOMATION_OPERATION.ATTRIBUTES,
            label: "Variables",
          },
        ];
      } else if (this.automation.contactOperations.includes(AUTOMATION_OPERATION.TAGS)) {
        return [
          {
            value: AUTOMATION_OPERATION.STAGES,
            label: "Stages",
          },
          {
            value: AUTOMATION_OPERATION.LIST,
            label: "List",
          },
          {
            value: AUTOMATION_OPERATION.ATTRIBUTES,
            label: "Variables",
          },
        ];
      } else {
        return [
          {
            value: AUTOMATION_OPERATION.TAGS,
            label: "Tags",
          },
          {
            value: AUTOMATION_OPERATION.STAGES,
            label: "Stages",
          },
          {
            value: AUTOMATION_OPERATION.LIST,
            label: "List",
          },
          {
            value: AUTOMATION_OPERATION.ATTRIBUTES,
            label: "Variables",
          },
        ];
      }
    },
    listOpt() {
      var checkedContacts = _.map(this.checkedContactsList, "id");
      var list = _.filter(this.contactsData, function (item) {
        return !checkedContacts.includes(item.id);
      });
      return _.union(this.checkedContactsList, list);
    },
    whatsappTemplates() {
      var templates = this.$store.state.whatsapp.templates || [];

      var items = _.filter(templates, function (item) {
        return item.status !== "REJECTED";
      });

      return items;
    },
    contentNodesFilter() {
      var nodes = this.contentNodes;
      var search = this.searchContentNode.toLowerCase();

      var items = _.filter(nodes, function (item) {
        return item.id.toLowerCase().includes(search);
      });

      return items;
    },
    tagsData() {
      return this.$store.state.outreach.tags.data || [];
    },
    stagesData() {
      return this.$store.state.outreach.stages.data || [];
    },
    contactsData() {
      return this.$store.state.outreach.contacts.data || [];
    },
    contactsCount() {
      return this.$store.state.outreach.contacts.count || 0;
    },
    cloudAPISettings() {
      return this.$store.state.modules.whatsapp.cloudAPIConfig;
    },
    loadMore() {
      return this.listOpt.length < this.contactsCount ? true : false;
    },
    pickerOptionsEnd() {
      var date = moment();
      if (this.startDate) {
        date = moment(this.startDate);
      }
      return {
        disabledDate(time) {
          return time.getDate() < date.date();
        },
      };
    },
  },
  mounted() {
    this.setupForm();
  },
  watch: {
    contactAttributesData(newVal) {
      if (newVal) {
        this.filterByCheckedAttributes(this.automation.contactAttributes, true);
      }
    },
  },
  methods: {
    async filterByCheckedAttributes(automationContactAttributes = [], isNew = false) {
      const filter = [];
      let attributes = _.cloneDeep(this.contactAttributesOpt);
      if (
        automationContactAttributes.length !== 0 &&
        typeof automationContactAttributes != "string"
      ) {
        attributes = automationContactAttributes;
      }
      for (let index = 0; index < attributes.length; index++) {
        if (!attributes[index].value || !attributes[index].comparison) {
          continue;
        }
        filter.push({
          variable: attributes[index].attribute,
          comparison: attributes[index].comparison,
          value: attributes[index].value,
        });
      }
      let count = 0;
      if (filter.length > 0) {
        count = await this.countContact(filter);
      }
      if (!isNew) {
        this.settingSubtitle = count + " Contacts";
      }
      this.settingCountContact = count + " Contacts";
    },
    getAttributesValue(key, record) {
      var values = _.find(this.contactAttributesOpt, (entity) => {
        if (entity.value === key) {
          return entity;
        }
      });
      if (values?.options && !values.options.includes(record.value)) {
        record.value = "";
      }
      return values?.options;
    },
    setupForm() {
      this.fetchWhatsappServer();
      this.setupChannels();
      this.setupContentNodes();
    },
    fetchWhatsappServer() {
      this.$store.dispatch("GET_WHATSAPP_SERVERS").then((data) => {
        this.whatsappServers = data;
      });
    },
    async fetchWhatsappTemplates() {
      if (this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP]?.settings.endpoint) {
        await this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
          data: { wa_endpoint: this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].settings.endpoint },
        });
      }
    },
    setupChannels() {
      this.channels = {};
      this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP] = {
        value: AUTOMATION_CHANNEL_NAME.WHATSAPP,
        label: "Whatsapp",
        selected: false,
        settings: {
          endpoint: "",
        },
      };
    },
    setupContentNodes() {
      var nodes = this.$store.state.nodes.content;
      var items = [];
      _.forEach(nodes, function (value, key) {
        value.id = key;
        items.push(value);
      });
      this.contentNodes = items;
    },
    chooseChannel(channel) {
      if (channel === AUTOMATION_CHANNEL_NAME.WHATSAPP) {
        this.openSettingView = true;
        this.currentSetting = "choose-channel-whatsapp";
        this.settingTitle = "Whatsapp";
        this.settingSubtitle = "";
        this.selectedServer = this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].settings.endpoint;
      } else {
        this.closeSetting();
      }
    },
    selectContacts(type) {
      this.settingSubtitle = "0 Contact(s)";
      if (type === AUTOMATION_OPERATION.TAGS) {
        this.openSettingView = true;
        this.currentSetting = "select-contacts-tags";
        this.settingTitle = "Tags";
        this.checkedTags = this.automation.contactTags;
      } else if (type === AUTOMATION_OPERATION.STAGES) {
        this.openSettingView = true;
        this.currentSetting = "select-contacts-stages";
        this.settingTitle = "Stages";
        this.checkedStages = this.automation.contactStages;
      } else if (type === AUTOMATION_OPERATION.LIST) {
        this.openSettingView = true;
        this.currentSetting = "select-contacts-list";
        this.settingTitle = "List";
        this.checkedContacts = this.automation.contactList;
        this.search = "";
        this.filter = [];
      } else if (type === AUTOMATION_OPERATION.ATTRIBUTES) {
        this.openSettingView = true;
        this.currentSetting = "select-contacts-attributes";
        this.settingTitle = "Variables";
        this.settingSubtitle = "";
        this.checkedAttributes = _.cloneDeep(this.automation.contactAttributes);
      } else {
        this.closeSetting();
      }
      this.fetchContacts(false);
    },
    setMessage(type) {
      if (type === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE) {
        this.fetchWhatsappTemplates();
        this.openSettingView = true;
        this.currentSetting = "set-message-whatsapp";
        this.settingTitle = "Whatsapp Template";
        this.settingSubtitle = "";
        this.checkedWATemplate = _.map(
          _.filter(this.automation.messages, {
            contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
          }),
          "contentId"
        );
        this.checkedWATemplateItem = _.filter(this.automation.messages, {
          contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
        });
        this.handleWATemplateChange();
      } else if (type === AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE) {
        this.openSettingView = true;
        this.currentSetting = "set-message-content-node";
        this.settingTitle = "Content Node";
        this.settingSubtitle = "";
        this.checkedContentNode = _.map(
          _.filter(this.automation.messages, {
            contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE,
          }),
          "contentId"
        );
      }
    },
    setContext() {
      this.openSettingView = true;
      this.currentSetting = "set-context";
      this.settingTitle = "Context";
      this.settingSubtitle = "";
      this.currentContext = this.automation.context;
    },
    updateProperties(type) {
      this.settingSubtitle = "";
      if (type === AUTOMATION_OPERATION.TAGS) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-tags";
        this.settingTitle = "Tags";
        this.propertiesOpt[AUTOMATION_OPERATION.TAGS].method =
          this.propertiesOpt[AUTOMATION_OPERATION.TAGS].methodItem;
        this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selected = _.map(
          this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selectedItem,
          "id"
        );
      } else if (type === AUTOMATION_OPERATION.STAGES) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-stages";
        this.settingTitle = "Stages";
        if (this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selectedItem) {
          this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selected =
            this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selectedItem.id;
        } else {
          this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selected = null;
        }
      } else if (type === AUTOMATION_OPERATION.ATTRIBUTES) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-attributes";
        this.settingTitle = "Variables";
        this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected =
          this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem;
      } else {
        this.closeSetting();
      }
    },
    updatePropertiesFailed(type) {
      this.settingSubtitle = "";
      if (type === AUTOMATION_OPERATION.TAGS) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-failed-tags";
        this.settingTitle = "Tags";
        this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method =
          this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].methodItem;
        this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selected = _.map(
          this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selectedItem,
          "id"
        );
      } else if (type === AUTOMATION_OPERATION.STAGES) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-failed-stages";
        this.settingTitle = "Stages";
        if (this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selectedItem) {
          this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected =
            this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selectedItem.id;
        } else {
          this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected = null;
        }
      } else if (type === AUTOMATION_OPERATION.ATTRIBUTES) {
        this.openSettingView = true;
        this.currentSetting = "update-properties-failed-attributes";
        this.settingTitle = "Variables";
        this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected =
          this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem;
      } else {
        this.closeSetting();
      }
    },
    sendOut(type) {
      if (type === AUTOMATION_SCHEDULE_TYPE.SEND_NOW) {
        this.automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.SEND_NOW;
        this.automation.scheduleCron = [];
        this.automation.startDate = moment().tz(this.clientTimezone);
        this.automation.endDate = null;
        this.sendOutSummary = "";
        this.closeSetting();
        this.saveAutomation();
      } else if (type === AUTOMATION_SCHEDULE_TYPE.SCHEDULED) {
        this.openSettingView = true;
        this.currentSetting = "send-out-schedule";
        this.settingTitle = "Schedule";
        this.settingSubtitle = "";
      } else if (type === AUTOMATION_SCHEDULE_TYPE.RECURRING) {
        this.openSettingView = true;
        this.currentSetting = "send-out-recurring";
        this.settingTitle = "Recurring";
        this.settingSubtitle = "";
      }
    },
    handleCheckAllContacts(val) {
      this.checkedContacts = val ? _.map(this.listOpt, "id") : [];
      this.isIndeterminate = false;
      this.settingSubtitle = this.checkedContacts.length + " Contacts";
    },
    handleCheckedContacts(value) {
      let checkedCount = value.length;
      this.checkAllContacts = checkedCount === this.listOpt.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listOpt.length;
      this.settingSubtitle = this.checkedContacts.length + " Contacts";
    },
    handleContactsChange() {
      let checkedCount = this.checkedContacts.length;
      this.checkAllContacts = checkedCount === this.listOpt.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.listOpt.length;
      this.settingSubtitle = this.checkedContacts.length + " Contacts";
    },
    _handleOperationChange() {
      this.automation.contactOperations = [];
      if (this.automation.contactTags.length > 0) {
        this.automation.contactOperations.push(AUTOMATION_OPERATION.TAGS);
      }
      if (this.automation.contactStages.length > 0) {
        if (this.automation.contactOperations.length > 0) {
          this.automation.contactOperations.push(this.operation1);
        }
        this.automation.contactOperations.push(AUTOMATION_OPERATION.STAGES);
      }
      if (this.automation.contactList.length > 0) {
        if (this.automation.contactOperations.length > 2) {
          this.automation.contactOperations.push(this.operation2);
        } else if (this.automation.contactOperations.length > 0) {
          this.automation.contactOperations.push(this.operation1);
        }
        this.automation.contactOperations.push(AUTOMATION_OPERATION.LIST);
      }
      if (this.automation.contactAttributes.length > 0) {
        if (this.automation.contactOperations.length > 4) {
          this.automation.contactOperations.push(this.operation3);
        } else if (this.automation.contactOperations.length > 2) {
          this.automation.contactOperations.push(this.operation2);
        } else if (this.automation.contactOperations.length > 0) {
          this.automation.contactOperations.push(this.operation1);
        }
        this.automation.contactOperations.push(AUTOMATION_OPERATION.ATTRIBUTES);
      }
    },
    handleOperationChange() {
      this._handleOperationChange();
      this.saveAutomation();
    },
    async handleTagsChange() {
      this.filter = [
        {
          variable: "tags",
          comparison: OUT_REACH_FILTER_COMPARISON.IS,
          value: "",
          valueArray: this.checkedTags,
        },
      ];
      await this.fetchContacts(false);
      this.settingSubtitle = this.contactsCount + " Contacts";
    },
    async handleStagesChange() {
      var total = 0;
      for (let index = 0; index < this.checkedStages.length; index++) {
        this.filter = [
          {
            variable: "stage",
            comparison: OUT_REACH_FILTER_COMPARISON.IS,
            value: this.checkedStages[index],
            valueArray: [],
          },
        ];
        await this.fetchContacts(false);
        total += this.contactsCount;
      }
      this.settingSubtitle = total + " Contacts";
    },
    _handleSearch() {
      this.filter = [
        {
          variable: "*",
          comparison: OUT_REACH_FILTER_COMPARISON.CONTAINS,
          value: this.search,
          valueArray: [],
        },
      ];
      this.fetchContacts(false);
    },
    handleSearch() {
      _.debounce(this._handleSearch, 500)();
    },
    handleLoadMore() {
      this.fetchContacts(true);
    },
    addContactAttributes() {
      this.checkedAttributes.push({
        attribute: "",
        operator: AUTOMATION_ATTRIBUTE_OPERATOR.EQUAL,
        value: "",
      });
    },
    handleWATemplateChange() {
      var whatsappTemplates = this.whatsappTemplates;
      _.forEach(this.checkedWATemplateItem, function (value) {
        const contentId = value.contentId;
        const item = _.find(whatsappTemplates, ["id", contentId]);
        value.content = item;
        value.headerText = "";
        value.bodyText = "";
        let paramaters = [];

        if (contentId && item) {
          const header = _.find(item.components, ["type", "HEADER"]);
          if (header) {
            const headerType = _.get(header, "format", "");
            let noOfHeaderParameters = 0;
            if (headerType === "TEXT") {
              value.headerText = _.get(header, "text", "");
              const headerTextMatches = _.get(header, "text", "").match(/({{\d+}})/g);
              if (Array.isArray(headerTextMatches)) {
                noOfHeaderParameters = headerTextMatches.length;
              }
              for (var i = 1; i <= noOfHeaderParameters; i++) {
                var prop = `hp${i}`;
                if (!value.contentParameters[prop]) {
                  value.contentParameters[prop] = {
                    key: prop,
                    label: `Header Parameter ${i}`,
                    value: "",
                    type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                  };
                }
                paramaters.push(value.contentParameters[prop]);
              }
            } else {
              if (!value.contentParameters["hm"]) {
                let paramLabel = "Header Media Link";
                if (headerType === "IMAGE") {
                  paramLabel = "Header Image Link";
                } else if (headerType === "DOCUMENT") {
                  paramLabel = "Header Document Link";
                } else if (headerType === "VIDEO") {
                  paramLabel = "Header Video Link";
                }
                value.contentParameters["hm"] = {
                  key: "hm",
                  label: paramLabel,
                  value: "",
                  type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                };
              }
              paramaters.push(value.contentParameters["hm"]);
            }
          }

          const body = _.find(item.components, ["type", "BODY"]);
          if (body) {
            let noOfBodyParameters = 0;
            value.bodyText = _.get(body, "text", "");
            const bodyTextMatches = _.get(body, "text", "").match(/({{\d+}})/g);
            if (Array.isArray(bodyTextMatches)) {
              noOfBodyParameters = bodyTextMatches.length;
            }
            for (var i = 1; i <= noOfBodyParameters; i++) {
              var prop = `p${i}`;
              if (!value.contentParameters[prop]) {
                value.contentParameters[prop] = {
                  key: prop,
                  label: `Body Parameter ${i}`,
                  value: "",
                  type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                };
              }
              paramaters.push(value.contentParameters[prop]);
            }
          }

          const buttons = _.find(item.components, ["type", "BUTTONS"]);
          if (buttons) {
            const noOfFooterParametersTuple = _.map(buttons.buttons, (button) => {
              if (button.type !== "URL") return 0;

              const footerParameterMatches = button.url.match(/({{\d+}})/g) || [];
              return footerParameterMatches.length;
            });
            for (var i = 1; i <= noOfFooterParametersTuple.length; i++) {
              const noOfParams = noOfFooterParametersTuple[i - 1];

              if (noOfParams === 0) continue;
              for (var k = 1; k <= noOfParams; k++) {
                var prop = `bp${i}${k}`;
                if (!value.contentParameters[prop]) {
                  value.contentParameters[prop] = {
                    key: prop,
                    label: `Button ${i} Parameter ${k}`,
                    value: "",
                    type: AUTOMATION_MESSAGE_PARAMETER_TYPE.TEXT,
                  };
                }
                paramaters.push(value.contentParameters[prop]);
              }
            }
          }
        }

        value.allParameters = paramaters;
      });
    },
    addMessageWATemplate() {
      this.checkedWATemplateItem.push({
        contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
        contentId: "",
        contentParameters: {},
        allParameters: [],
      });
      this.handleWATemplateChange();
    },
    addUpdatePropertiesAttr(failed) {
      if (failed) {
        this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected.push({
          key: "",
          value: "",
        });
      } else {
        this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected.push({
          key: "",
          value: "",
        });
      }
    },
    closeSetting() {
      this.openSettingView = false;
      this.currentSetting = "";
      this.settingTitle = "";
      this.settingSubtitle = "";
    },
    resetSetting() {
      if (this.currentSetting === "choose-channel-whatsapp") {
        this.selectedServer = "";
      } else if (this.currentSetting === "select-contacts-tags") {
        this.checkedTags = [];
      } else if (this.currentSetting === "select-contacts-stages") {
        this.checkedStages = [];
      } else if (this.currentSetting === "select-contacts-list") {
        this.checkedContacts = [];
        this.handleContactsChange();
      } else if (this.currentSetting === "select-contacts-attributes") {
        this.checkedAttributes = [];
      } else if (this.currentSetting === "set-message-whatsapp") {
        this.checkedWATemplate = [];
        this.checkedWATemplateItem = [];
      } else if (this.currentSetting === "set-message-content-node") {
        this.checkedContentNode = [];
      } else if (this.currentSetting === "set-context") {
        this.currentContext = "";
      } else if (this.currentSetting === "update-properties-tags") {
        this.propertiesOpt[AUTOMATION_OPERATION.TAGS].method = "";
        this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selected = [];
      } else if (this.currentSetting === "update-properties-stages") {
        this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selected = null;
      } else if (this.currentSetting === "update-properties-attributes") {
        this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected = [];
      } else if (this.currentSetting === "update-properties-failed-tags") {
        this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method = "";
        this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selected = [];
      } else if (this.currentSetting === "update-properties-failed-stages") {
        this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected = null;
      } else if (this.currentSetting === "update-properties-failed-attributes") {
        this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected = [];
      } else if (this.currentSetting === "send-out-schedule") {
        this.startDate = "";
        this.startTime = "";
      } else if (this.currentSetting === "send-out-recurring") {
        this.startDate = "";
        this.startTime = "";
        this.endFlag = "";
        this.endDate = "";
        this.endTime = "";
        this.repeatType = "";
        this.repeatTime = 1;
        this.repeatDates = "";
      }
    },
    saveSetting() {
      if (this.currentSetting === "choose-channel-whatsapp") {
        if (this.selectedServer) {
          this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].selected = true;
          this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].settings.endpoint = this.selectedServer;
        } else {
          this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].selected = false;
          this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].settings.endpoint = "";
        }

        var channels = [];
        _.forOwn(this.channels, function (value, key) {
          if (value.selected) {
            channels.push({
              name: key,
              settings: value.settings,
            });
          }
        });
        this.automation.channels = channels;
      } else if (
        this.currentSetting === "select-contacts-tags" ||
        this.currentSetting === "select-contacts-stages" ||
        this.currentSetting === "select-contacts-list" ||
        this.currentSetting === "select-contacts-attributes"
      ) {
        if (this.currentSetting === "select-contacts-tags") {
          this.automation.contactTags = this.checkedTags;
          var checkedTags = this.checkedTags;
          this.checkedTagsList = _.filter(this.tagsData, function (item) {
            return checkedTags.includes(item.id);
          });
        } else if (this.currentSetting === "select-contacts-stages") {
          this.automation.contactStages = this.checkedStages;
          var checkedStages = this.checkedStages;
          this.checkedStagesList = _.filter(this.stagesData, function (item) {
            return checkedStages.includes(item.id);
          });
        } else if (this.currentSetting === "select-contacts-list") {
          this.automation.contactList = this.checkedContacts;
          var checkedContacts = this.checkedContacts;
          this.checkedContactsList = _.filter(this.contactsData, function (item) {
            return checkedContacts.includes(item.id);
          });
        } else if (this.currentSetting === "select-contacts-attributes") {
          this.automation.contactAttributes = this.checkedAttributes;
        }
        this._handleOperationChange();
      } else if (
        this.currentSetting === "set-message-whatsapp" ||
        this.currentSetting === "set-message-content-node"
      ) {
        var messageWATemplates = [];
        var messageContentNodes = [];

        _.forEach(this.automation.messages, function (value) {
          if (value.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE) {
            messageWATemplates.push(value);
          } else if (value.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE) {
            messageContentNodes.push(value);
          }
        });

        var messages = [];
        if (this.currentSetting === "set-message-whatsapp") {
          var checkedWATemplateList = [];
          _.forEach(this.checkedWATemplateItem, function (value) {
            if (value.contentId) {
              let parameters = _.reduce(
                value.allParameters,
                function (result, params) {
                  result[params.key] = params;
                  return result;
                },
                {}
              );
              messages.push({
                contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE,
                contentId: value.contentId,
                contentName: value.content.name,
                contentParameters: parameters,
              });
              checkedWATemplateList.push(value.content);
            }
          });
          messageWATemplates = messages;
          this.checkedWATemplateList = checkedWATemplateList;
        } else if (this.currentSetting === "set-message-content-node") {
          var checkedContentNodeList = [];
          var contentNodes = this.contentNodes;
          _.forEach(this.checkedContentNode, function (value) {
            messages.push({
              contentType: AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE,
              contentId: value,
              contentName: value,
            });
            var item = _.find(contentNodes, ["id", value]);
            checkedContentNodeList.push(item);
          });
          messageContentNodes = messages;
          this.checkedContentNodeList = checkedContentNodeList;
        }
        this.automation.messages = messageWATemplates.concat(messageContentNodes);
      } else if (this.currentSetting === "set-context") {
        this.automation.context = this.currentContext;
      } else if (
        this.currentSetting === "update-properties-tags" ||
        this.currentSetting === "update-properties-stages" ||
        this.currentSetting === "update-properties-attributes" ||
        this.currentSetting === "update-properties-failed-tags" ||
        this.currentSetting === "update-properties-failed-stages" ||
        this.currentSetting === "update-properties-failed-attributes"
      ) {
        if (this.currentSetting === "update-properties-tags") {
          this.propertiesOpt[AUTOMATION_OPERATION.TAGS].methodItem =
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].method;
          var selected = this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selected;
          this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selectedItem = _.filter(
            this.tagsData,
            function (item) {
              return selected.includes(item.id);
            }
          );
          var updateTo = _.map(this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selectedItem, "name");
          this.propertiesOpt[AUTOMATION_OPERATION.TAGS].updateTo = updateTo.join(", ");
        } else if (this.currentSetting === "update-properties-stages") {
          var item = _.find(this.stagesData, [
            "id",
            this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selected,
          ]);
          this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selectedItem = item;
          this.propertiesOpt[AUTOMATION_OPERATION.STAGES].updateTo = item ? item.name : "";
        } else if (this.currentSetting === "update-properties-attributes") {
          this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem =
            this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected;
          var updateTo = _.map(
            this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem,
            "key"
          );
          this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].updateTo = updateTo.join(", ");
        } else if (this.currentSetting === "update-properties-failed-tags") {
          this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].methodItem =
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method;
          var selected = this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selected;
          this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selectedItem = _.filter(
            this.tagsData,
            function (item) {
              return selected.includes(item.id);
            }
          );
          var updateTo = _.map(
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selectedItem,
            "name"
          );
          this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].updateTo = updateTo.join(", ");
        } else if (this.currentSetting === "update-properties-failed-stages") {
          var item = _.find(this.stagesData, [
            "id",
            this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected,
          ]);
          this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selectedItem = item;
          this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].updateTo = item ? item.name : "";
        } else if (this.currentSetting === "update-properties-failed-attributes") {
          this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem =
            this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected;
          var updateTo = _.map(
            this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem,
            "key"
          );
          this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].updateTo = updateTo.join(", ");
        }

        var updates = [];
        _.forOwn(this.propertiesOpt, function (value, key) {
          if (key === AUTOMATION_OPERATION.TAGS && value.selected.length > 0) {
            updates.push({
              property: key,
              updateTo: value.selected,
              failed: false,
              method: value.methodItem ? value.methodItem : AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          } else if (key === AUTOMATION_OPERATION.STAGES && value.selected) {
            updates.push({
              property: key,
              updateTo: [value.selected],
              failed: false,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          } else if (key === AUTOMATION_OPERATION.ATTRIBUTES && value.selected.length > 0) {
            let items = _.reduce(
              value.selected,
              function (result, item) {
                result[item.key] = item.value;
                return result;
              },
              {}
            );
            updates.push({
              property: key,
              updateTo: [],
              updateItem: items,
              failed: false,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          }
        });
        _.forOwn(this.propertiesFailedOpt, function (value, key) {
          if (key === AUTOMATION_OPERATION.TAGS && value.selected.length > 0) {
            updates.push({
              property: key,
              updateTo: value.selected,
              failed: true,
              method: value.methodItem ? value.methodItem : AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          } else if (key === AUTOMATION_OPERATION.STAGES && value.selected) {
            updates.push({
              property: key,
              updateTo: [value.selected],
              failed: true,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          } else if (key === AUTOMATION_OPERATION.ATTRIBUTES && value.selected.length > 0) {
            let items = _.reduce(
              value.selected,
              function (result, item) {
                result[item.key] = item.value;
                return result;
              },
              {}
            );
            updates.push({
              property: key,
              updateTo: [],
              updateItem: items,
              failed: true,
              method: AUTOMATION_UPDATE_METHOD.OVERRIDE,
            });
          }
        });
        this.automation.updates = updates;
      } else if (this.currentSetting === "send-out-schedule") {
        if (this.startDate) {
          this.automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.SCHEDULED;
          this.automation.startDate = moment(this.startDate).tz(this.clientTimezone);

          if (this.startTime) {
            var startTime = this.startTime.split(":");
            this.automation.startDate.hours(parseInt(startTime[0]));
            this.automation.startDate.minutes(parseInt(startTime[1]));

            var cronMinute = String(this.automation.startDate.minute());
            var cronHour = String(this.automation.startDate.hour());
            var cronDate = String(this.automation.startDate.date());
            var cronMonth = String(this.automation.startDate.month() + 1);

            this.automation.scheduleCron = [[cronMinute, cronHour, cronDate, cronMonth, "*"]];
          }

          this.automation.endDate = _.cloneDeep(this.automation.startDate).add(30, "minutes");
          this.sendOutSummary = this.automation.startDate.format("MMMM Do YYYY, h:mm a");
        } else {
          this.automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.NOT_SET;
          this.automation.scheduleCron = [];
          this.automation.startDate = null;
          this.automation.endDate = null;
          this.sendOutSummary = "";
        }
      } else if (this.currentSetting === "send-out-recurring") {
        if (this.startDate) {
          this.automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.RECURRING;
          this.automation.startDate = moment(this.startDate).tz(this.clientTimezone);

          if (this.startTime) {
            var startTime = this.startTime.split(":");
            this.automation.startDate.hours(parseInt(startTime[0]));
            this.automation.startDate.minutes(parseInt(startTime[1]));
          }

          if (this.endFlag === "on") {
            this.automation.endDate = moment(this.endDate).tz(this.clientTimezone);
            if (this.endTime) {
              var endTime = this.endTime.split(":");
              this.automation.endDate.hours(parseInt(endTime[0]));
              this.automation.endDate.minutes(parseInt(endTime[1]));
            }
          } else {
            this.automation.endDate = null;
          }

          var cronMinute = String(this.automation.startDate.minute());
          var cronHour = String(this.automation.startDate.hour());
          var cronDay = String(this.automation.startDate.day());
          var cronDate = String(this.automation.startDate.date());
          var cronMonth = String(this.automation.startDate.month() + 1);
          if (this.repeatType === "Daily") {
            if (this.repeatTime == 1) {
              this.automation.scheduleCron = [[cronMinute, cronHour, "*", "*", "*"]];
              this.sendOutSummary = "Every day at " + this.automation.startDate.format("h:mm a");
            } else if (this.repeatTime == 2) {
              this.automation.scheduleCron = [[cronMinute, cronHour, "*/2", "*", "*"]];
              this.sendOutSummary = "Every 2 days at " + this.automation.startDate.format("h:mm a");
            }
          } else if (this.repeatType === "Weekly") {
            this.automation.scheduleCron = [[cronMinute, cronHour, "*", "*", cronDay]];
            this.sendOutSummary =
              "Every week on " + this.automation.startDate.format("dddd h:mm a");
          } else if (this.repeatType === "Monthly") {
            this.automation.scheduleCron = [[cronMinute, cronHour, cronDate, "*", "*"]];
            this.sendOutSummary = "Every month on " + this.automation.startDate.format("Do h:mm a");
          } else if (this.repeatType === "Yearly") {
            this.automation.scheduleCron = [[cronMinute, cronHour, cronDate, cronMonth, "*"]];
            this.sendOutSummary =
              "Every year on " + this.automation.startDate.format("MMMM Do h:mm a");
          } else if (this.repeatType === "Dates") {
            var repeatDates = document.getElementById("repeatDates").value;
            repeatDates = repeatDates.split(", ");

            var scheduleCron = [];
            var sendOutSummary = "Every ";
            var clientTimezone = this.clientTimezone;
            var endDate = moment(this.startDate).tz(this.clientTimezone);
            _.forEach(repeatDates, function (value, key) {
              var selectedDate = moment(value).tz(clientTimezone);
              if (selectedDate.isAfter(endDate)) {
                endDate = moment(value).tz(clientTimezone);
              }
              var date = String(selectedDate.date());
              var month = String(selectedDate.month() + 1);
              scheduleCron.push([cronMinute, cronHour, date, month, "*"]);
              if (key === 0) {
                sendOutSummary += selectedDate.format("MMMM Do YYYY");
              } else {
                sendOutSummary += ", " + selectedDate.format("MMMM Do YYYY");
              }
            });
            this.automation.endDate = endDate;
            this.automation.scheduleCron = scheduleCron;
            this.sendOutSummary =
              sendOutSummary + " at " + this.automation.startDate.format("h:mm a");
          } else {
            this.automation.scheduleCron = [];
            this.sendOutSummary = "";
          }

          if (this.repeatType !== "Dates") {
            if (this.endFlag === "on") {
              this.sendOutSummary +=
                " till " + this.automation.endDate.format("MMMM Do YYYY, h:mm a");
            } else {
              this.sendOutSummary += " till never";
            }
          }
        } else {
          this.automation.scheduleType = AUTOMATION_SCHEDULE_TYPE.NOT_SET;
          this.automation.scheduleCron = [];
          this.automation.startDate = null;
          this.automation.endDate = null;
          this.sendOutSummary = "";
        }
        this.repeatSummary = this.sendOutSummary;
      }
      this.closeSetting();
      this.saveAutomation();
    },
    canChangeStatus() {
      if (this.automation.id && this.automation.scheduleType !== AUTOMATION_SCHEDULE_TYPE.NOT_SET) {
        if (
          this.channels[AUTOMATION_CHANNEL_NAME.WHATSAPP].selected &&
          this.checkedWATemplateList.length > 0
        ) {
          this.$emit("enable", true);
        } else {
          this.$emit("enable", false);
        }
      } else {
        this.$emit("enable", false);
      }
    },
    async changeStatus(status) {
      if (status) {
        await this.onEnable();
        if (this.automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.SEND_NOW) {
          await this.onExecute();
          this.$message({
            message: "Your automation is now enabled. Messages are being sent to the contacts.",
            type: "success",
          });
        } else {
          this.$message({
            message: "Your automation is now enabled",
            type: "success",
          });
        }
      } else {
        this.onDisable();
        this.$message({
          message: "Your automation is disabled.",
          type: "warning",
        });
      }
    },
    async editAutomation(id) {
      this.filter = [];
      this.fetchContacts(false);

      this.automation.id = id;
      await this.findOne(id);

      for (var index = 0; index < this.automation.channels.length; index++) {
        this.channels[this.automation.channels[index].name].selected = true;
        this.channels[this.automation.channels[index].name].settings =
          this.automation.channels[index].settings;
      }

      await this.fetchWhatsappTemplates();

      var checkedStages = this.automation.contactStages;
      this.checkedStagesList = _.filter(this.stagesData, function (item) {
        return checkedStages.includes(item.id);
      });

      var checkedTags = this.automation.contactTags;
      this.checkedTagsList = _.filter(this.tagsData, function (item) {
        return checkedTags.includes(item.id);
      });

      var checkedContacts = this.automation.contactList;
      this.checkedContactsList = _.filter(this.contactsData, function (item) {
        return checkedContacts.includes(item.id);
      });

      if (this.automation.contactOperations.length >= 3) {
        this.operation1 = this.automation.contactOperations[1];
      }
      if (this.automation.contactOperations.length === 7) {
        this.operation1 = this.automation.contactOperations[1];
        this.operation2 = this.automation.contactOperations[3];
        this.operation3 = this.automation.contactOperations[5];
      } else if (this.automation.contactOperations.length === 5) {
        this.operation1 = this.automation.contactOperations[1];
        this.operation2 = this.automation.contactOperations[3];
      } else if (this.automation.contactOperations.length === 3) {
        this.operation1 = this.automation.contactOperations[1];
      }

      var messageWATemplates = [];
      var messageContentNodes = [];
      _.forEach(this.automation.messages, function (value) {
        if (value.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE) {
          messageWATemplates.push(value);
        } else if (value.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.CONTENT_NODE) {
          messageContentNodes.push(value);
        }
      });

      var checkedWATemplateList = [];
      var whatsappTemplates = this.whatsappTemplates;
      _.forEach(messageWATemplates, function (value) {
        const item = _.find(whatsappTemplates, ["id", value.contentId]);
        if (item) {
          checkedWATemplateList.push(item);
        }
      });
      this.checkedWATemplateList = checkedWATemplateList;

      var checkedContentNodeList = [];
      var contentNodes = this.contentNodes;
      _.forEach(messageContentNodes, function (value) {
        var item = _.find(contentNodes, ["id", value.contentId]);
        if (item) {
          checkedContentNodeList.push(item);
        }
      });
      this.checkedContentNodeList = checkedContentNodeList;

      for (var index = 0; index < this.automation.updates.length; index++) {
        const value = this.automation.updates[index];
        if (
          value.property === AUTOMATION_OPERATION.TAGS ||
          value.property === OUT_REACH_TAG_TYPE.TAG
        ) {
          if (value.failed) {
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].method = value.method;
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].methodItem = value.method;
            var selected = value.updateTo;
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selected = selected;
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selectedItem = _.filter(
              this.tagsData,
              function (item) {
                return selected.includes(item.id);
              }
            );
            var updateTo = _.map(
              this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].selectedItem,
              "name"
            );
            this.propertiesFailedOpt[AUTOMATION_OPERATION.TAGS].updateTo = updateTo.join(", ");
          } else {
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].method = value.method;
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].methodItem = value.method;
            var selected = value.updateTo;
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selected = selected;
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selectedItem = _.filter(
              this.tagsData,
              function (item) {
                return selected.includes(item.id);
              }
            );
            var updateTo = _.map(
              this.propertiesOpt[AUTOMATION_OPERATION.TAGS].selectedItem,
              "name"
            );
            this.propertiesOpt[AUTOMATION_OPERATION.TAGS].updateTo = updateTo.join(", ");
          }
        } else if (
          value.property === AUTOMATION_OPERATION.STAGES ||
          value.property === OUT_REACH_TAG_TYPE.STAGE
        ) {
          if (value.failed) {
            var item = _.find(this.stagesData, ["id", value.updateTo[0]]);
            this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selected = value.updateTo[0];
            this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].selectedItem = item;
            this.propertiesFailedOpt[AUTOMATION_OPERATION.STAGES].updateTo = item ? item.name : "";
          } else {
            var item = _.find(this.stagesData, ["id", value.updateTo[0]]);
            this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selected = value.updateTo[0];
            this.propertiesOpt[AUTOMATION_OPERATION.STAGES].selectedItem = item;
            this.propertiesOpt[AUTOMATION_OPERATION.STAGES].updateTo = item ? item.name : "";
          }
        } else if (value.property === AUTOMATION_OPERATION.ATTRIBUTES) {
          let items = _.reduce(
            value.updateItem,
            function (result, value, key) {
              result.push({
                key: key,
                value: value,
              });
              return result;
            },
            []
          );
          if (value.failed) {
            this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected = items;
            this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem = items;
            var updateTo = _.map(
              this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem,
              "key"
            );
            this.propertiesFailedOpt[AUTOMATION_OPERATION.ATTRIBUTES].updateTo =
              updateTo.join(", ");
          } else {
            this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selected = items;
            this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem = items;
            var updateTo = _.map(
              this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].selectedItem,
              "key"
            );
            this.propertiesOpt[AUTOMATION_OPERATION.ATTRIBUTES].updateTo = updateTo.join(", ");
          }
        }
      }

      if (this.automation.startDate) {
        this.startDate = moment(this.automation.startDate).tz(this.clientTimezone);
      } else {
        this.automation.startDate = moment().tz(this.clientTimezone);
        this.startDate = moment().tz(this.clientTimezone);
      }
      this.startTime = this.startDate.format("HH:mm");
      if (this.automation.endDate) {
        this.endFlag = "on";
        this.endDate = moment(this.automation.endDate).tz(this.clientTimezone);
        this.endTime = this.endDate.format("HH:mm");
      } else {
        this.endFlag = "never";
        this.endDate = null;
        this.endTime = null;
      }

      this.repeatType = "";
      this.repeatTime = 1;
      this.repeatDates = "";
      this.sendOutSummary = "";
      if (this.automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED) {
        this.sendOutSummary = this.startDate.format("MMMM Do YYYY, h:mm a");
      }

      if (this.automation.scheduleCron.length > 1) {
        this.repeatType = "Dates";
        var startDate = moment(this.automation.startDate);
        _.forEach(this.automation.scheduleCron, function (value, key) {
          var selectedDate = moment(this.automation.startDate);
          selectedDate.date(value[2]);
          selectedDate.month(value[3]);
          if (startDate.isAfter(selectedDate)) {
            selectedDate.add(1, "years");
          }
          if (key === 0) {
            this.sendOutSummary += selectedDate.format("MMMM Do YYYY");
          } else {
            this.sendOutSummary += ", " + selectedDate.format("MMMM Do YYYY");
          }
        });
        this.sendOutSummary += " at " + this.startDate.format("h:mm a");
      } else if (this.automation.scheduleCron.length === 1) {
        var cron = this.automation.scheduleCron[0];
        if (cron[4] !== "*") {
          this.repeatType = "Weekly";
          this.sendOutSummary = "Every week on " + this.startDate.format("dddd h:mm a");
        } else if (cron[3] !== "*") {
          this.repeatType = "Yearly";
          this.sendOutSummary = "Every year on " + this.startDate.format("MMMM Do h:mm a");
        } else if (cron[2] === "*/2") {
          this.repeatType = "Daily";
          this.repeatTime = 2;
          this.sendOutSummary = "Every 2 days at " + this.startDate.format("h:mm a");
        } else if (cron[2] !== "*") {
          this.repeatType = "Monthly";
          this.sendOutSummary = "Every month on " + this.startDate.format("Do h:mm a");
        } else {
          this.repeatType = "Daily";
          this.sendOutSummary = "Every day at " + this.startDate.format("h:mm a");
        }

        if (this.endFlag === "on") {
          this.sendOutSummary += " till " + this.endDate.format("MMMM Do YYYY, h:mm a");
        } else {
          this.sendOutSummary += " till never";
        }
      }
      this.repeatSummary = this.sendOutSummary;
      this.canChangeStatus();
    },
    createAutomation() {
      if (this.automation.id) {
        this.$confirm(
          "You are currently editing an automation. Save this and create a new one?",
          "Warning",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        ).then(() => {
          this.newAutomation();
        });
      } else {
        this.newAutomation();
      }
    },
    newAutomation() {
      this.automation = {
        id: uuid(),
        name: "",
        context: "",
        channels: [],
        messages: [],
        contactTags: [],
        contactStages: [],
        contactList: [],
        contactAttributes: [],
        contactOperations: [],
        scheduleType: AUTOMATION_SCHEDULE_TYPE.NOT_SET,
        scheduleCron: [],
        startDate: null,
        endDate: null,
        sentTime: null,
        updates: [],
      };
      this.saveAutomation();
    },
    async saveAutomation() {
      if (!this.automation.id) {
        this.automation.id = uuid();
      }
      await this.onUpsert();
      this.canChangeStatus();
    },
    async fetchContacts(append) {
      await this.$store.dispatch("FETCH_OUTREACH_CONTACTS", {
        take: append ? this.contactsData.length + 25 : 25,
        page: 1,
        startDate: moment().subtract(1, "years"),
        endDate: moment(),
        filter: this.filter,
        sort: this.sort,
      });
      this.handleContactsChange();
    },
    async countContact(filter) {
      const count = await this.$store.dispatch("FETCH_COUNT_OUTREACH_CONTACT", {
        take: this.contactsData.length + 25,
        page: 1,
        filter,
      });
      return count;
    },
    async findOne(id) {
      try {
        let response = await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                findOne(id: $id) {
                  id
                  name
                  context
                  enabled
                  channels
                  messages
                  contactTags
                  contactStages
                  contactList
                  contactAttributes
                  contactOperations
                  scheduleType
                  scheduleCron
                  startDate
                  endDate
                  sentTime
                  updates
                  createdAt
                  updatedAt
                }
              }
            }
          `,
          variables: {
            id: id,
          },
        });

        var data = _.get(response, "data.outreachAutomationAPI.findOne", null);
        if (data) {
          this.automation = data;
        } else {
          this.automation.id = null;
        }
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
        this.automation.id = null;
      }
    },
    async onUpsert() {
      this.$emit("saving", true);
      try {
        const automation = _.cloneDeep(this.automation);
        const { scheduleType, startDate, endDate } = automation;
        const shouldProcessCronOnServer =
          scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED ||
          scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING;

        if (shouldProcessCronOnServer) {
          automation.schedule = {
            scheduleType,
            startDate,
            endDate,
            repeatType: this.repeatType,
            repeatDates: this.repeatDates,
            repeatTime: this.repeatTime,
          };
        }

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($automation: JSON!) {
              outreachAutomationAPI {
                upsert(automation: $automation) {
                  id
                  name
                  channels
                  messages
                  updates
                  startDate
                  endDate
                }
              }
            }
          `,
          variables: {
            automation,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      } finally {
        this.$emit("saving", false);
      }
    },
    async onEnable() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                enable(id: $id)
              }
            }
          `,
          variables: {
            id: this.automation.id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    async onDisable() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                disable(id: $id)
              }
            }
          `,
          variables: {
            id: this.automation.id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    async onExecute() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                execute(id: $id)
              }
            }
          `,
          variables: {
            id: this.automation.id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    formatLabel(url, wa_id) {
      return wa_id ? `${wa_id} (${url})` : url;
    },
  },
};
</script>

<style lang="scss">
.automation-item {
  padding: 25px;
}

.automation-item-setting {
  padding: 0px 20px;
  max-height: 500px;
  overflow: auto;
}

.automation-item-list {
  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  max-height: 35px;
  overflow: auto;
}

.automation-title {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
}

.automation-label {
  margin-bottom: 17px;
  font-size: 16px;
  font-weight: 500;
}

.automation-input-label {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 600;
}

.automation-operation {
  width: 80px;
  margin-right: 10px;
}

.automation-divider.el-divider--horizontal {
  margin: 0px;
}

.tags-item.el-checkbox,
.tags-item.el-radio {
  margin-left: 0px;
  display: block;
  font-size: 14px;
}
.tags-item.el-radio {
  margin-bottom: 10px;
}
.automation-action {
  float: right;
}

.automation-btn.el-button.is-round {
  background: #ffffff;
  border-color: #1d57d8;
  color: #1d57d8;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 20px;
  text-transform: capitalize;
}

.automation-btn.el-button.is-round.active,
.automation-btn.el-button.is-round:hover,
.automation-btn.el-button.is-round:focus {
  background: #1d57d8;
  border-color: #1d57d8;
  color: #ffffff;
}

.automation-btn.el-button.is-circle {
  background: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  padding: 3px;
  margin-left: 15px;
}

.automation-btn.el-button.is-circle span i {
  font-size: 14px;
  font-weight: 600;
}

.automation-btn-gray.el-button.is-circle {
  background: rgba(42, 65, 87, 0.24);
  border-color: rgba(42, 65, 87, 0.24);
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 4px 3px 3px;
}

.tab-campaign .el-checkbox__label,
.tab-campaign .el-radio__label {
  font-size: 14px;
  margin-bottom: 10px;
}

.automation-item-setting-container {
  .el-card__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }
  .automation-item-setting-footer {
    box-sizing: border-box;
    padding-top: 10px;
    padding-right: 10px;
    border-top: 1px solid #ebeef5;
    width: 100%;
    text-align: right;
    font-size: 14px;
  }
}
</style>
