<template>
  <div class="form-container" v-loading="loading">
    <div class="form-container--left">
      <div class="form-list--header">
        <span>Forms</span>
        <span>
          <el-button @click="createForm" size="mini">
            <el-icon name="plus"></el-icon> Create form
          </el-button>
        </span>
      </div>

      <div class="form-list--wrapper">
        <div class="form-list--left">
          <form-list
            :forms="forms"
            :selected="form.id"
            @add="createForm"
            @select="selectForm"
            @refresh="fetchForms"
          />
        </div>
        <el-pagination
          style="margin-top: 10px"
          @current-change="handleCurrentChange"
          :page-size="10"
          background
          layout="prev, pager, next"
          :total="totalForms"
        >
        </el-pagination>
      </div>
    </div>

    <div class="form-container--right" v-if="form.id">
      <el-tabs v-model="activeFormTab" @tab-click="formTabClick">
        <el-tab-pane label="Configuration" name="config">
          <form-detail
            :form="form"
            :updateHandler="updateForm"
            :deleteHandler="deleteForm"
          ></form-detail>
        </el-tab-pane>
        <el-tab-pane label="Results" name="results">
          <form-result
            :form="form"
            :results="formResults"
            :current="formResults.current"
            :limit="formResults.limit"
            :searchDate="searchDate"
            :searchKeyword="searchKeyword"
            @currentChange="formResults.current = $event"
            @sizeChange="formResults.limit = $event"
            @searchDateChange="searchDate = $event"
            @searchKeywordChange="searchKeyword = $event"
            @exportResults="exportResults"
            v-loading="resultLoading"
          ></form-result>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import FormList from "./FormList";
import FormResult from "./FormResult";
import FormDetail from "./FormDetail";

import { rest } from "@/store/api";
import _ from "lodash";
import XLSX from "xlsx";
import moment from "moment";

const defaultFormResult = {
  list: [],
  total: 0,
  current: 1,
  limit: 10,
};
const now = new Date();
const defaultSearchQuery = {
  searchDate: [now, now],
  searchKeyword: "",
};

export default {
  name: "ConversationalForm",
  components: {
    FormList,
    FormResult,
    FormDetail,
  },
  data() {
    return {
      activeFormTab: "config",
      form: {
        id: "",
      },
      forms: [],
      formResults: { ...defaultFormResult },
      loading: false,
      resultLoading: false,
      searchKeyword: defaultSearchQuery.searchKeyword,
      searchDate: defaultSearchQuery.searchDate,
      totalForms: 0,
    };
  },
  methods: {
    formTabClick(e) {
      this.activeFormTab = e.name;
    },
    async deleteForm() {
      try {
        this.loading = true;
        await rest("delete", `forms/v1/${this.form.id}`, {});
        this.formResults = { ...defaultFormResult };
        _.remove(this.forms, (form) => form.id === this.form.id);
        this.form = {
          id: "",
        };
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async createForm() {
      try {
        this.loading = true;
        const response = await rest("post", "forms/v1", {});
        this.form = response.data;
        this.forms.unshift(this.form);
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async updateForm() {
      try {
        this.loading = true;
        const response = await rest("put", `forms/v1/${this.form.id}`, {
          ..._.omit(this.form, ["updatedAt", "createdAt", "id"]),
        });
        this.form = {
          ...response.data,
          settings: JSON.parse(response.data.settings),
        };
        this.forms.splice(_.findIndex(this.forms, { id: this.form.id }), 1, this.form);
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async fetchForms(page = 1) {
      try {
        this.loading = true;
        const response = await rest("get", `forms/v1?page=${page}`);
        if (response.data) {
          const { count, rows: forms } = response.data;
          this.forms = forms.map((form) => {
            return {
              ..._.omit(form, ["updatedAt", "createdAt"]),
              settings: JSON.parse(form.settings),
            };
          });
          this.totalForms = count;
        }
      } finally {
        this.loading = false;
      }
    },
    async fetchFormResults() {
      try {
        this.resultLoading = true;
        const { limit, current } = this.formResults;

        let queryString = `page=${current}&limit=${limit}`;
        if (this.searchDate && this.searchDate.length == 2) {
          const [startDate, endDate] = this.searchDate;
          queryString += `&startDate=${moment(startDate).startOf("day").toISOString()}`;
          queryString += `&endDate=${moment(endDate).endOf("day").toISOString()}`;
        }
        if (this.searchKeyword !== "") {
          queryString += `&searchKeyword=${this.searchKeyword}`;
        }
        const response = await rest("get", `forms/v1/${this.form.id}/results?${queryString}`);
        if (response.success) {
          this.formResults = response.data;
        }
      } catch (e) {
        this.$alert("Error while loading results. Please try again.");
      } finally {
        this.resultLoading = false;
      }
    },
    async selectForm(formId) {
      this.form = _.find(this.forms, (form) => form.id === formId);
      if (this.activeFormTab === "results") {
        this.formResults = { ...defaultFormResult };
        await this.fetchFormResults();
      }
    },
    async exportResults() {
      const response = await rest("get", `forms/v1/${this.form.id}/results/export`);
      if (response.success) {
        const results = response.data;
        const resultKeys = Object.keys(this.form.settings.fields.properties);
        let wb = XLSX.utils.book_new();
        wb.Props = {
          formID: this.form.id,
          formName: this.form.name,
          formDescription: this.form.description,
          formFields: resultKeys.join(","),
        };

        let wsData = [
          [
            "ResultID",
            "UID",
            "Session",
            "StateID",
            "Meta",
            ...resultKeys.map((key) => `Field_${key}`),
            "Status",
            "CreatedAt",
            "UpdatedAt",
          ],
        ];
        for (const row of results) {
          const wsRow = [];
          _.forOwn(_.omit(row, ["formId"]), (v, k) => {
            if (k === "result") {
              const r = v;
              //there is possible of undefined keys
              const values = [];
              _.forEach(resultKeys, (key) => {
                values.push(_.get(r, key, undefined));
              });
              wsRow.push(...values);
            } else {
              wsRow.push(v);
            }
          });
          wsData.push(wsRow);
        }

        let ws = XLSX.utils.aoa_to_sheet(wsData);

        const sheetName = `Form#${this.form.id}`;
        XLSX.utils.book_append_sheet(wb, ws, sheetName);

        const filename = this.$exportFilename(`${this.form.name} - ${this.form.id}`, "xlsx");
        XLSX.writeFile(wb, filename, {});

        this.$message.success({
          title: "Download Success",
          message: "Successfully downloaded results",
        });
      }
    },
    handleCurrentChange(page) {
      this.fetchForms(page);
    },
  },
  mounted() {
    this.fetchForms();
  },
  watch: {
    async activeFormTab(val) {
      if (val === "results") {
        await this.fetchFormResults();
      }
    },
    async "formResults.current"() {
      await this.fetchFormResults();
    },
    async "formResults.limit"() {
      if (this.formResults.current !== 1) {
        this.formResults.current = 1;
        return;
      }
      await this.fetchFormResults();
    },
    async searchDate() {
      if (this.formResults.current !== 1) {
        this.formResults.current = 1;
        return;
      }
      await this.fetchFormResults();
    },
    async searchKeyword() {
      if (this.formResults.current !== 1) {
        this.formResults.current = 1;
        return;
      }
      await this.fetchFormResults();
    },
  },
};
</script>

<style scoped lang="scss">
@import "./styles/form";
</style>
